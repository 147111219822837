import { data } from 'jquery';
import { apiUrl } from './Config';
import { http } from './httpServices';

export function AddProductWithDetails(productDetails) {
  return http.post(`${apiUrl}/admin/product`, productDetails);
}

export function GetAllProducts({ token }) {
  return http.get(`${apiUrl}/admin/product`, {
    headers: {
      'x-access-token': token,
    },
  });
}
export function GetProducts({ petCategory = '', productType=[],search='', limit=10, skip=0 }) {
  
  return http.post(`${apiUrl}/user/products/petCategory?limit=${limit}&skip=${skip}&search=${search}`, {
    petCategory,
    productType
  });
}
export function GetSingleProductDetails({ productId }) {
  return http.get(`${apiUrl}/admin/product/${productId}`);
}

export function UpdateSingleProductDetail({ productId, productDetails }) {
  return http.patch(`${apiUrl}/admin/product/${productId}`, { productDetails });
}

export function DeleteSingleProduct({ productId }) {
  return http.delete(`${apiUrl}/admin/product/${productId}`);
}

// =================== Product Category services ==========================
export function GetProductsWithTypeId({ limit, skip, petCategory }) {
  return http.get(
    `${apiUrl}/user/products?limit=${limit}&skip=${skip}&petCategory=${petCategory}`
  );
}

export function GetProductsByCategoryAndType({ productType, petCategory, limit, skip='', search=''}) {
  return http.get(
    `${apiUrl}/user/products/petCategory/${petCategory}/productType/${productType}?limit=${limit || 10}&skip=${skip}&search=${search}`
  );
}

export function PlaceOrderFromCartItems({ token, payloadData }) {
  return http.post(`${apiUrl}/user/order`, payloadData, {
    headers: {
      'x-access-token': token,
      loginType: 2,
    },
  });
}

export function GetAllProductType() {
  return http.get(
    `${apiUrl}/admin/product-type?limit=20&skip=0&search`
  );
}

// Pet Service 
export function GetPetCategory() {
  return http.get(
    `${apiUrl}/admin/pet-category`
  );
}

