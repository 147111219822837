import React from 'react';
import PageMain from '../Components/Page/Main';
import PageIntroHeader from './PageIntroHeader';
import { Link } from 'react-router-dom';

export default function Contact() {
  return (
    <PageMain>
      <PageIntroHeader PageName={' Contact Us '} />
      {/* <!-- ==================== Contact Start Here ==================== --> */}
      <section className="contact-top pt-120">
        <div className="container">
          <div className="row gy-4 justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6 ">
              <div className="contact-item">
                <div className="contact-item__thumb">
                  <img src="/assets/images/icons/contact01.png" alt="" />
                </div>
                <div className="contact-item__content">
                  <h5 className="contact-item__title">9017184321 </h5>
                  <p className="contact-item__desc"> For any query, call us </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 ">
              <div className="contact-item">
                <div className="contact-item__thumb">
                  <img src="/assets/images/icons/contact02.png" alt="" />
                </div>
                <div className="contact-item__content">
                  <h5 className="contact-item__title"> We are located at </h5>
                  <p className="contact-item__desc">
                    PLOT NO. 109 SHASTRI NAGAR HISAR ROAD ROHTAK Rohtak Rohtak
                    Rohtak Haryana - 124001 India
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 ">
              <div className="contact-item">
                <div className="contact-item__thumb">
                  <img src="/assets/images/icons/contact03.png" alt="" />
                </div>
                <div className="contact-item__content">
                  <h5 className="contact-item__title">
                    {' '}
                    <Link to={'/contact'}>support@petocareo.com</Link>
                  </h5>
                  <p className="contact-item__desc">Love to hear from you on</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6  d-lg-none d-block">
              <div className="contact-item">
                <div className="contact-item__thumb">
                  <img src="/assets/images/icons/contact01.png" alt="" />
                </div>
                <div className="contact-item__content">
                  <h5 className="contact-item__title"> 000 - 8888 - 9999 </h5>
                  <p className="contact-item__desc">
                    {' '}
                    Accompanied By English versions{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==================== Contact End Here ==================== -->
<!-- ==================== Contact Form & Map Start Here ==================== --> */}
      <div className="contact-bottom py-120 ">
        <div className="container">
          <div
            className="bg-img"
            style={{
              backgroundImage: 'url(assets/images/thumbs/contact-bg.png)',
            }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-bottom__inner">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-lg-9 col-md-10 col-sm-10">
                      <div className="contactus-form">
                        <div className="contactus-form__thumb-one">
                          <img
                            src="/assets/images/thumbs/contact03.png"
                            alt=""
                          />
                        </div>
                        <div className="contactus-form__thumb-two">
                          <img
                            src="/assets/images/thumbs/contact04.png"
                            alt=""
                          />
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="section-heading">
                              <h4 className="section-heading__title style-four">
                                Send Your Message{' '}
                                <span className="section-heading__bars"></span>
                              </h4>
                            </div>
                          </div>
                          <form action="#" autocomplete="off">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label for="name" className="mb-2">
                                    Your Name<span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form--control"
                                    id="name"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label for="emailaddress" className="mb-2">
                                    Email Address
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form--control"
                                    id="emailaddress"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label for="message" className="mb-2">
                                    Your Message{' '}
                                  </label>
                                  <textarea
                                    className="form--control"
                                    id="message"
                                  ></textarea>
                                </div>
                              </div>
                              <div className="col-sm-12 text-center">
                                <button className="btn btn--base pill">
                                  {' '}
                                  SEMD YOUR MESSAGE{' '}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageMain>
  );
}
