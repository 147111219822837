import { Navigate, useRoutes } from 'react-router-dom';

import Home from './pages/Home';
import Signup from './pages/User/SignUp';
import SignIn from './pages/User/SignIn';
import ResetPassword from './pages/User/ResetPassword';
import About from './pages/About';
import Contact from './pages/Contact';
import Consult from './pages/Consult';
import ProductDetails from './pages/ProductDetails';
import ProductCategory from './pages/ProductCategory';
import AddToCart from './pages/AddToCart';
import Checkout from './pages/Checkout';
import PageNotFound404 from './pages/PageNotFound404';
import VerifyOTP from './pages/User/VerifyOTP';
import ForgotPassword from './pages/User/ForgotPassword';
import Privacy from './pages/Company/Privacy';
import TermsConditions from './pages/Company/TermsConditions';
import RefundPolocy from './pages/Company/RefundPolocy';
import ShippingPolicy from './pages/Company/ShippingPolicy';
import Faqs from './pages/Company/Faqs';
import ProductFilters from './pages/Product_Filter';
import PaymentSuccess from './pages/Payments/PaymentSuccess';
import PaymentFailure from './pages/Payments/PaymentFailure';
import UserOrders from './pages/UserOrders';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/signup',
      element: <Signup />,
    },
    {
      path: '/signin',
      element: <SignIn />,
    },
    {
      path: '/resetPassword',
      element: <ResetPassword />,
    },
    {
      path: '/verifyOTP',
      element: <VerifyOTP />,
    },
    {
      path: '/forgotPassword',
      element: <ForgotPassword />,
    },
    { path: '/about', element: <About /> },
    { path: '/contact', element: <Contact /> },
    { path: '/consult', element: <Consult /> },
    {
      path: '/product_details/:productId',
      element: <ProductDetails />,
    },
    {
      path: '/product_details',
      element: <ProductDetails />,
    },
    {
      // path: '/productcategory/:categoryId/:productId',
      path: '/productcategory/:categoryId/:productType',
      element: <ProductCategory />,
    },
    {
      // path: '/productcategory/:categoryId/:productId',
      path: '/productcategory',
      element: <ProductCategory />,
    },
    {
      // path: '/productcategory/:categoryId/:productId',
      path: '/productcategory2',
      element: <ProductFilters />,
    },

    { path: '/cart', element: <AddToCart /> },
    { path: '/checkout', element: <Checkout /> },
    { path: '/paymentsuccess', element: <PaymentSuccess /> },
    { path: '/paymentfailure', element: <PaymentFailure /> },

    { path: '/refund', element: <RefundPolocy /> },
    { path: '/privacy', element: <Privacy /> },
    { path: '/terms&conditions', element: <TermsConditions /> },
    { path: '/ShippingPolicy', element: <ShippingPolicy /> },
    { path: '/faqs', element: <Faqs /> },

    { path: '/userProfile', element: <UserOrders /> },
    { path: '/userOrders', element: <UserOrders /> },

    // privacy and terms

    { path: '/', element: <Home /> },
    { path: '*', element: <PageNotFound404 /> },
  ]);
}
