import React from 'react';

import HomeMain from '../Components/Home/Main';
import HomeHeader from '../Components/Home/Header';
import Footer from '../Components/Home/Footer/Footer';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <div>
      {/* <!--==================== Overlay Start ====================--> */}
      <div className="body-overlay"></div>
      {/* <!--==================== Overlay End ====================--> */}
      {/* <!--==================== Sidebar Overlay End ====================--> */}
      <div className="sidebar-overlay"></div>
      {/* <!--==================== Sidebar Overlay End ====================--> */}
      {/* <!-- ==================== Scroll to Top End Here ==================== --> */}
      <Link to={'/'} className="scroll-top">
        <i className="fas fa-angle-double-up"></i>
      </Link>
      <HomeHeader />
      <HomeMain />

      {/* <!-- ========footer===== --> */}
      <Footer />
      {/* <PageFooter /> */}
    </div>
  );
}
