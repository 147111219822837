import React from 'react';
import Form from 'react-bootstrap/Form';

const SliderProton = ({ value, changePrice }) => {
  return (
    <div style={{ padding: '0px 8px' }}>
      <Form.Range
        value={value}
        onChange={changePrice}
        tooltip="on"
        min={1000}
        max={5000}
        style={{
          thumb: {
            backgroundColor: '#000',
          },
          track: {
            backgroundColor: '#000',
          },
          range: {
            backgroundColor: `rgba(0, 0, 0, 0.26)`,
          },
        }}
      />
    </div>
  );
};

export default SliderProton;
