import React, { useEffect, useState } from 'react';
import PageMain from '../Components/Page/Main';
import PageIntroHeader from './PageIntroHeader';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Joi from 'joi-browser';
import TextField from '../Components/Form/TextField';
import { useSelector } from 'react-redux';
import { GetAllProductsInCart } from '../Services/cartServices';
import { PlaceOrderFromCartItems } from '../Services/productService';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';

export default function Checkout() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [authToken, setAuthToken] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [cartListItems, setCartListItems] = useState([]);
  const [totalCost, setTotalCost] = useState(0);

  const [formData, setFormData] = useState({
    fullName: '',
    fullAddress: '',
    mobileNo: '',
    postalCode: '',
  });

  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState('');

  const schema = {
    fullName: Joi.string().required().label('Full Name'),
    fullAddress: Joi.string().required().label('Full Address'),
    mobileNo: Joi.string().required().min(10).max(10).label('Phone No'),
    postalCode: Joi.string().required().min(6).max(6).label('Postal Code'),
  };

  const validate = () => {
    const validationErrors = {};
    const result = Joi.validate(formData, schema, {
      abortEarly: false,
    });
    console.log(result);
    if (!result.error) return {};

    // eslint-disable-next-line no-return-assign
    result.error.details.map(
      (item) => (validationErrors[item.path[0]] = item.message)
    );
    return validationErrors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaObj = { [name]: schema[name] };
    const result = Joi.validate(obj, schemaObj);
    if (!result.error) return {};
    return result.error.details[0].message;
  };

  const handleChange = ({ target }) => {
    const fieldData = { ...formData };
    const fieldErrors = { ...errors };

    const errorMessage = validateProperty(target);
    if (Object.keys(errorMessage).length > 0)
      fieldErrors[target.name] = errorMessage;
    else delete fieldErrors[target.name];

    fieldData[target.name] = target.value;
    setFormData({ ...fieldData });
    setErrors({ ...fieldErrors });
  };

  const handleSubmit = async (e) => {
    console.log('handle submit called');
    e.preventDefault();
    setShowLoader(true);

    // Validate form data
    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      console.log('Errors ===>', formErrors);
      setErrors({ ...errors, ...formErrors });
      setShowLoader(false);
      return;
    }
    // Prepare order data
    const data = {
      products:
        cartListItems.length > 0 &&
        cartListItems[0].products.map((item) => ({
          productId: item.productId,
          quantity: item.quantity,
        })),
      totalItemsQuantity:
        cartListItems.length > 0 && cartListItems[0].products.length,
      totalAmount: totalCost,
      address: formData.fullAddress,
      pinCode: formData.postalCode,
      mobileNumber: formData.mobileNo,
    };

    try {
      // Place the order
      const response = await PlaceOrderFromCartItems({
        token: authToken,
        payloadData: data,
      });
      console.log('response ===>', response);

      // Razorpay options
      let key = 'rzp_test_CA0jKux26bgSTO';
      const options = {
        key,
        amount: 1,
        image: 'https://petocareo.s3.amazonaws.com/1707336076397success.png',
        currency: 'INR',
        name: 'Petocareo.com',
        description: 'RazorPay',
        order_id: response.data.data.id,
        callback_url: 'https://api.petocareo.com/user/payment/verification',
        prefill: {
          name: 'Rohit Dangi',
          email: 'petocareoteam@gmail.com',
          contact: '9017184321',
        },
        notes: {
          address: 'Petocareo.com',
        },
        theme: {
          color: '#121212',
        },
      };

      // Initialize Razorpay
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error('Error placing order:', error);
      setFormSubmitError('Failed to place order');
      toast.error('An External Error occours !');
    } finally {
      setShowLoader(false);
    }
  };

  // setting token
  useEffect(() => {
    if (auth.accessToken !== undefined) {
      console.log('token set ');
      setAuthToken(auth.accessToken);
    } else {
      console.log('not set');
    }
  }, [auth.accessToken]);

  // fetch products from cart
  useEffect(() => {
    const fetchCartProducts = async () => {
      try {
        const { data: dataSets } = await GetAllProductsInCart({
          token: authToken,
        });
        if (dataSets.statusCode === 200) {
          setCartListItems(dataSets.data);
        }
        setFetched(true);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    !fetched && fetchCartProducts();
  }, [auth, authToken]);

  const calculateTotalCost = () => {
    return cartListItems.reduce((total, cartItem) => {
      return (
        total +
        cartItem.products.reduce((subtotal, product) => {
          return subtotal + product.price * product.quantity;
        }, 0)
      );
    }, 0);
  };

  // Calculate total cost on page amount or reload
  useEffect(() => {
    const amount = calculateTotalCost();
    setTotalCost(amount);
  }, [cartListItems]);

  return (
    <PageMain>
      <PageIntroHeader PageName={' Checkout'} />
      <div className="checkout-section py-120">
        <div className="container">
          <div className="row gy-4">
            <Typography variant="h6" color={'darkred'} textAlign={'center'}>
              {formSubmitError}
            </Typography>
            <div className="col-lg-8">
              <div className="billing-details">
                <h5 className="billing-details__title">Billing Details </h5>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <TextField
                        name={'fullName'}
                        data={formData}
                        errors={errors}
                        label={'Full Name'}
                        handleOnChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <TextField
                        name={'mobileNo'}
                        data={formData}
                        errors={errors}
                        label={'Mobile No'}
                        handleOnChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <TextField
                        name={'postalCode'}
                        data={formData}
                        errors={errors}
                        label={'Postal Code'}
                        handleOnChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <TextField
                        name={'fullAddress'}
                        data={formData}
                        errors={errors}
                        label={'Full Address'}
                        handleOnChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="billing-details__button d-flex flex-wrap justify-content-between align-items-center">
                      {/* <Link
                        to={'/productcategory'}
                        className="btn btn--base pill billing-details__button-margin"
                      >
                        Continue Shopping
                      </Link>
                      <Link to="/cart" className="color-style">
                        Return to cart
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="your-order">
                <h6 className="your-order__title">Your Order</h6>
                {cartListItems.length > 0
                  ? cartListItems[0].products.map((item, i) => (
                      <div
                        key={i}
                        className="order d-flex justify-content-between align-items-center"
                      >
                        <ul className="order-title">
                          <li
                            className="order-title__one"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {item.productName}
                          </li>
                        </ul>
                        <span className="order__number">
                          {' ₹'}
                          {item.price}
                        </span>
                      </div>
                    ))
                  : null}
                <div className="order d-flex justify-content-between align-items-center">
                  <ul className="order-title">
                    <li className="order-title__one"> Total </li>
                  </ul>
                  <span className="order__number">
                    {' '}
                    {' ₹'}
                    {totalCost}
                  </span>
                </div>

                <div className="place-order">
                  <Button onClick={handleSubmit} className="btn btn--base pill">
                    {' '}
                    ORDER NOW
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageMain>
  );
}
