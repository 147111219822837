import React from 'react';
import Form from 'react-bootstrap/Form';

const CheckboxProton = ({ changeChecked, cuisine }) => {
  const { checked, label, id } = cuisine;

  return (
    <div>
      <Form.Check
        type="checkbox"
        id={`checkbox-${id}`}
        label={label}
        checked={checked}
        onChange={() => changeChecked(id)}
        inline
      />
    </div>
  );
};

export default CheckboxProton;
