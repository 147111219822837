import { apiUrl } from '../Config';
import { http } from '../httpServices';

export function SignUpUser({ name, email, password, mobileNumber }) {
  return http.post(`${apiUrl}/user/signup`, {
    name,
    email,
    password,
    mobileNumber,
  });
}

export function SignInUser({ email, password }) {
  return http.post(`${apiUrl}/user/login`, {
    email,
    password,
  });
}

export function VerifyGeneratedOTP({ email, otp, isForgotPassword }) {
  return http.post(`${apiUrl}/user/verify/otp`, {
    email,
    otp,
    isForgotPassword,
  });
}

export function ResendOtp({ email }) {
  return http.post(`${apiUrl}/user/resend/otp`, {
    email,
  });
}

export function ResetUserPassword({ email }) {
  return http.post(`${apiUrl}/user/reset/password`, {
    email,
  });
}

export function ForgotUserPassword({ email }) {
  return http.post(`${apiUrl}/user/forgot/password`, {
    email,
  });
}

export function GetLoginedUserDetails({ token }) {
  return http.get(`${apiUrl}/user/userDetails`, {
    headers: {
      'x-access-token': token,
      loginType: 2,
    },
  });
}
