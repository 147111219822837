import React, { useEffect, useState } from 'react';
import PageMain from '../Components/Page/Main';
import PageIntroHeader from './PageIntroHeader';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FiPlus, FiMinus } from 'react-icons/fi';
import CustomDialog from '../Components/Modals/Modals';
import { Button } from 'react-bootstrap';
import {
  DeleteSingleProductsFromCart,
  GetAllProductsInCart,
} from '../Services/cartServices';

export default function AddToCart() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [authToken, setAuthToken] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [cartListItems, setCartListItems] = useState([]);
  const [totalCost, setTotalCost] = useState(0);

  // for handling dialog
  const [showDialog, setshowDialog] = useState(false);
  const dialogTitle = 'Authorization Alert';
  const dialogDescription = 'Please login to add product to your cart';

  const handleDialogClose = () => setshowDialog(false);
  const handleLogin = () => navigate('/signin');
  const createDialogFooter = () => {
    return (
      <>
        <Button
          variant="primary"
          className="btn btn--base-two pill btn--sm"
          onClick={handleLogin}
        >
          Login
        </Button>
        <Button
          variant="light"
          className="btn rounded-pill btn--sm text-dark"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
      </>
    );
  };

  // setting token
  useEffect(() => {
    if (auth.accessToken !== undefined) {
      console.log('token set ');
      setAuthToken(auth.accessToken);
    } else {
      console.log('not set');
    }
  }, [auth.accessToken]);

  // fetch products
  useEffect(() => {
    const fetchCartProducts = async () => {
      try {
        const { data: dataSets } = await GetAllProductsInCart({
          token:auth.accessToken });
        console.log('fetchCartProducts =====>', dataSets);
        if (dataSets.statusCode === 200) {
          setCartListItems(dataSets.data);
        }
        setFetched(true);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    !fetched && fetchCartProducts();
  }, [auth, authToken]);

  const handleQuantityChange = (productName, increment) => {
    const updatedCartItems = cartListItems.map((cartItem) => {
      return {
        ...cartItem,
        products: cartItem.products.map((product) => {
          if (product.productName === productName) {
            return {
              ...product,
              quantity: increment ? product.quantity + 1 : product.quantity - 1,
            };
          }
          return product;
        }),
      };
    });
    const amount = calculateTotalCost(updatedCartItems);
    setTotalCost(amount);
    setCartListItems(updatedCartItems);
  };

  const handleIncreaseProductQuantity = (productName) => {
    handleQuantityChange(productName, true);
  };

  const handleDecreaseProductQuantity = (productName) => {
    handleQuantityChange(productName, false);
  };

  const deleteProductFromCart = async (productId) => {
    // call api to delete product form cart 664e53a8b1b4da5c23609ed0
    const deletetedCartResponse = await DeleteSingleProductsFromCart({
      token: authToken,
      cartId: cartListItems.length > 0 && cartListItems[0]._id,
      productId: productId,
    });
    console.log('deletetedCartResponse =====>', deletetedCartResponse);
    setCartListItems((prevItems) => {
      const updatedCartItems = prevItems.map((cartItem) => {
        if (cartItem.products.some((item) => item.productId === productId)) {
          const updatedProducts = cartItem.products.filter(
            (item) => item.productId !== productId
          );
          const deletedItem = cartItem.products.find(
            (item) => item.productId === productId
          );
          const deletedItemCost = deletedItem.price * deletedItem.quantity;
          setTotalCost((prevTotalCost) => prevTotalCost - deletedItemCost);
          return { ...cartItem, products: updatedProducts };
        }
        return cartItem;
      });
      return updatedCartItems.filter(
        (cartItem) => cartItem.products.length > 0
      );
    });
  };

  // const calculateTotalCost = () => {
  //   let totalCost = 0;
  //   cartListItems.forEach((cartItem) => {
  //     cartItem.products.forEach((product) => {
  //       totalCost += product.price * product.quantity;
  //     });
  //   });
  //   return totalCost;
  // };

  // // calculating the total amount
  // useEffect(() => {
  //   const amount = calculateTotalCost();
  //   setTotalCost(amount);
  // }, []);

  const calculateTotalCost = () => {
    return cartListItems.reduce((total, cartItem) => {
      return (
        total +
        cartItem.products.reduce((subtotal, product) => {
          return subtotal + product.price * product.quantity;
        }, 0)
      );
    }, 0);
  };

  // Calculate total cost on page mount or reload
  useEffect(() => {
    const amount = calculateTotalCost();
    setTotalCost(amount);
  }, [cartListItems]);

  return (
    <PageMain>
      <CustomDialog
        show={showDialog}
        title={dialogTitle}
        description={dialogDescription}
        footer={createDialogFooter()}
        handleClose={handleDialogClose}
      />
      <PageIntroHeader PageName={'Cart'} />
      <div className="cart-section py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h3 className="section-heading__title style-two">
                  {' '}
                  Your Cart Items{' '}
                  <span className="section-heading__bars"></span>
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <table className="table table--responsive--lg cart-table">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Until Price</th>
                    <th>Qty</th>
                    <th>Subtotal</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {cartListItems &&
                  cartListItems.length > 0 &&
                  cartListItems[0].products.length > 0 ? (
                    cartListItems[0].products.map((eachItem, i) => (
                      <tr key={i}>
                        <td data-label="Product Name">
                          <div className="customer">
                            <div className="customer__thumb">
                              <img
                                src={eachItem.productImage[0]}
                                alt={eachItem.productName}
                              />
                            </div>
                            <div className="customer__content">
                              <h6 className="customer__name">
                                {eachItem.productName}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td data-label="Unit Price"> ₹{eachItem.price} </td>
                        <td data-label="Qty">
                          <div className="qty-cart d-flex style-two">
                            <div className="product-qty">
                              <button
                                type="button"
                                className="product-qty__decrement"
                                onClick={(e) =>
                                  handleDecreaseProductQuantity(
                                    eachItem.productName
                                  )
                                }
                              >
                                <FiMinus />
                              </button>
                              <input
                                type="number"
                                className="product-qty__value"
                                value={eachItem.quantity}
                                readOnly
                              />
                              <button
                                type="button"
                                className="product-qty__increment"
                                onClick={(e) =>
                                  handleIncreaseProductQuantity(
                                    eachItem.productName
                                  )
                                }
                              >
                                <FiPlus />
                              </button>
                            </div>
                          </div>
                        </td>
                        <td data-label="Subtotal">
                          {' '}
                          ₹{eachItem.price * eachItem.quantity}{' '}
                        </td>
                        <td data-label="Delete">
                          <button
                            type="button"
                            className="delete-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="Delete"
                            onClick={() => {
                              deleteProductFromCart(eachItem.productId);
                            }}
                          >
                            {' '}
                            <i className="las la-trash-alt"></i>{' '}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">
                        <h6 className="customer__name text-center">
                          The product card is currently empty, indicating that
                          there are no products to display.
                        </h6>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="cart-btn-area d-flex justify-content-between flex-wrap">
            <div className="shopping-cart mb-0">
              <Link
                to="/productcategory"
                className="btn btn--base pill shopping-cart__pr mb-3"
              >
                CONTINUE SHOPPING
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="order-summery ms-auto">
                <div className="order-summery__two d-flex justify-content-between">
                  <h6 className="order-summery__title-two"> Grand Total : </h6>
                  <span className="order-summery__number-two">
                    ₹{totalCost}
                  </span>
                </div>
                <div className="checkout">
                  <Link to="/checkout" className="btn btn--base pill">
                    PROCEED TO CHECKOUT
                  </Link>
                  <p className="checkout__desc">
                    Checkout With Multiple Addresses
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Feature section  */}
      <div
        className="feature-section bg-img py-60"
        style={{
          backgroundImage: 'url(assets/images/thumbs/feature/feature-img.png)',
        }}
      >
        <div className="container">
          <div className="row gy-4">
            <div className="col-md-3 col-sm-6 col-xxsm-6">
              <div className="feature-item">
                <div className="feature-item__thumb">
                  <img src="/assets/images/thumbs/feature/f04.png" alt="" />
                </div>
                <div className="feature-item__info">
                  <h5 className="feature-item__title">FREE SHIPPING</h5>
                  <span className="feature-item__payment">
                    {' '}
                    For All Order Over $99{' '}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xxsm-6">
              <div className="feature-item">
                <div className="feature-item__thumb">
                  <img src="/assets/images/thumbs/feature/f03.png" alt="" />
                </div>
                <div className="feature-item__info">
                  <h5 className="feature-item__title">FRIENDLY SUPPORT</h5>
                  <span className="feature-item__payment">
                    {' '}
                    24/7 Customer Support{' '}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xxsm-6">
              <div className="feature-item">
                <div className="feature-item__thumb">
                  <img src="/assets/images/thumbs/feature/f02.png" alt="" />
                </div>
                <div className="feature-item__info">
                  <h5 className="feature-item__title">SECURE PAYMENT</h5>
                  <span className="feature-item__payment">
                    100% Secure Payment
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xxsm-6">
              <div className="feature-item">
                <div className="feature-item__thumb">
                  <img src="/assets/images/thumbs/feature/f01.png" alt="" />
                </div>
                <div className="feature-item__info">
                  <h5 className="feature-item__title">SHIPPING & RETURN</h5>
                  <span className="feature-item__payment">
                    {' '}
                    within 30days For Refund{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageMain>
  );
}
