// import React from 'react';

// export default function PaymentFailure() {
//   return <div>PaymentFailure</div>;
// }

import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import PageMain from '../../Components/Page/Main';
import PageIntroHeader from '../PageIntroHeader';
import { Box, Button, Typography } from '@mui/material';

export default function PaymentFailure() {
  const [searchParams] = useSearchParams();
  const reference = searchParams.get('reference');
  return (
    <div>
      <PageMain>
        <PageIntroHeader PageName={'Product Order Status'} />

        <div className="cart-section py-100">
          <div className="container">
            <div className="row mt-40 mb-50">
              <Typography
                variant="h5"
                color={'InfoText'}
                textAlign={'center'}
                gutterBottom
              >
                Your Order has been Failed due to some External error, please
                contact product owner or Product admin for any dispute
              </Typography>

              <Typography
                variant="h6"
                color={'GrayText'}
                textAlign={'center'}
                gutterBottom
              >
                Your payment reference no is {reference} save it for further use
                !
              </Typography>
              <br />
              <Typography textAlign={'center'}>
                <Box mt={3}>
                  <Button
                    LinkComponent={Link}
                    to={'/'}
                    variant="small"
                    color="success"
                    className="btn btn--base pill"
                    style={{ backgroundColor: '#453934', color: 'whitesmoke' }}
                  >
                    back to Home
                  </Button>
                </Box>
              </Typography>
            </div>
          </div>
        </div>
      </PageMain>
    </div>
  );
}
