import React from 'react';

import HeaderTopTwo from './HeaderTopTwo';
import HeaderTwo from './HeaderTwo';
import NavSearchBoxOverlay from './NavSearchBoxOverlay';

export default function HomeHeader() {
  return (
    <div>
      <HeaderTopTwo />
      <HeaderTwo />
      <NavSearchBoxOverlay />
    </div>
  );
}
