import axios from 'axios';
// import { toast } from 'react-toastify';
// axios intercepors for response
// axios.interceptors.response.use(
//   (res) => {
//     if (res.headers['x-auth-token']) {
//       const token = res.headers['x-auth-token'];
//       localStorage.setItem('token', token);
//     }
//     return res;
//   },
//   (error) => {
//     const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

//     // error rathe than expectedall are unexpectedrrors so
//     if (!expectedError) {
//       console.log('consoling the error', error);
//       toast.error('An unexpected error occour');
//     }
//     return Promise.reject(error);
//   }
// );

// configuring the request
// const config = (req) => {
//   // const token = localStorage.getItem("token");
//   const token = localStorage.getItem('token');
//   if (token) req.headers['x-auth-token'] = token;
//   return req;
// };
// axios.interceptors.request.use(config, (error) => ({}));
export const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};
