import React from 'react';
import PageMain from '../Components/Page/Main';
import PageIntroHeader from './PageIntroHeader';
import { Card, Button, Row, Col } from 'react-bootstrap';

export default function Consult() {
  const experts = [
    {
      name: 'Dr. Dinesh Dangi (BVSC and AH)',
      expertise: 'Pets',
      contactNumber: '+919017184321',
      imageUrl: '/assets/images/people/dineshDangi-new.jpg',
    },
    {
      name: 'Dr. Kiran',
      expertise: 'Pets',
      contactNumber: '+918221850786',
      imageUrl: '/assets/images/people/kiran.jpg',
    },
    {
      name: 'Dr. Deepak Dahiya (BVSC and AH)',
      expertise: 'Pet Expert',
      contactNumber: '+918221850786',
      imageUrl: '/assets/images/people/dipak.jpg',
    },
  ];
  return (
    <PageMain>
      <PageIntroHeader PageName={'Consult a vet Expert'} />

      <div className="about-section py-120">
        <div className="container">
          <div className="row gy-5 flex-wrap-reverse align-items-center">
            {experts.map((expert, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={4} className="mb-4">
                <Card style={{ width: '18rem' }}>
                  <Card.Img
                    variant="top"
                    src={expert.imageUrl}
                    alt={`${expert.name}`}
                  />
                  <Card.Body>
                    <Card.Title>{`${expert.name}`}</Card.Title>
                    <Card.Text>
                      <p>Expertise: {expert.expertise}</p>
                      <p>Contact Number: {expert.contactNumber}</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </div>
        </div>
      </div>
    </PageMain>
  );
}
