import React, { useEffect, useState } from 'react';

import PageIntroHeader from '../PageIntroHeader';
import PageMain from '../../Components/Page/Main';
import Joi from 'joi-browser';
import TextField from '../../Components/Form/TextField';
import Checkbox from '../../Components/Form/Checkbox';
// import { SignInUser } from '../../Services/UserAuth';
import { Link, useNavigate } from 'react-router-dom';
import { LoginUserWithThunk } from '../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.auth);
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState('');
  const [data, setData] = useState({
    email: 'petocareoteam+1@gmail.com',
    password: 'Petclinic@123',
    terms: true,
  });

  useEffect(() => {
    console.log('auth', accessToken);
  }, [accessToken]);

  const schema = {
    email: Joi.string().required().label('Email'),
    password: Joi.string().required().label('Password'),
    terms: Joi.boolean().required().label('Terms'),
  };

  const validate = () => {
    const validationErrors = {};
    const result = Joi.validate(data, schema, {
      abortEarly: false,
    });
    console.log(result);
    if (!result.error) return {};

    // eslint-disable-next-line no-return-assign
    result.error.details.map(
      (item) => (validationErrors[item.path[0]] = item.message)
    );
    return validationErrors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaObj = { [name]: schema[name] };
    const result = Joi.validate(obj, schemaObj);
    if (!result.error) return {};
    return result.error.details[0].message;
  };

  const handleChange = ({ target }) => {
    const fieldData = { ...data };
    const fieldErrors = { ...errors };

    const errorMessage = validateProperty(target);
    if (Object.keys(errorMessage).length > 0)
      fieldErrors[target.name] = errorMessage;
    else delete fieldErrors[target.name];

    if (target.type === 'checkbox') {
      fieldData[target.name] = !Boolean(data.terms);
    } else {
      fieldData[target.name] = target.value;
    }
    setData({ ...fieldData });
    setErrors({ ...fieldErrors });
  };

  const handleSubmit = async (e) => {
    console.log('handle submit called');
    e.preventDefault();
    setShowLoader(true);
    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      console.log('Errors ===>', errors);
      setErrors({ ...errors, ...formErrors });
      setShowLoader(false);
    } else if (Object.keys(formErrors).length === 0) {
      dispatch(
        LoginUserWithThunk(
          { email: data.email, password: data.password },
          (data, error) => {
            if (error) {
              setFormSubmitError(error.message);
            }
            setShowLoader(false);
            if (data.data.statusCode === 200) {
              return navigate('/');
            } else {
              setFormSubmitError(data.data.message);
            }
          }
        )
      );
    }
  };

  return (
    <PageMain>
      <PageIntroHeader PageName={'Login'} />
      <section className="account py-120">
        <div className="account-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 d-none d-lg-block">
                <div className="account-thumb">
                  <img src="/assets/images/thumbs/login-img.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="account-form">
                  <div className="account-form__content mb-4">
                    <h3 className="account-form__title mb-2">
                      {' '}
                      Sign In To Your Account{' '}
                    </h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    {formSubmitError && (
                      <h5 className="text-danger text-center">
                        {formSubmitError}
                      </h5>
                    )}
                    <div className="row">
                      <div className="col-sm-12">
                        <TextField
                          name={'email'}
                          data={data}
                          errors={errors}
                          label={'Email'}
                          handleOnChange={handleChange}
                        />
                      </div>
                      <div className="col-sm-12">
                        <TextField
                          name={'password'}
                          type={'Password'}
                          data={data}
                          errors={errors}
                          label={'Password'}
                          handleOnChange={handleChange}
                        />
                        <p className="have-account__text text-start mb-4">
                          <Link to={'/forgotPassword'}>Forgot Password ?</Link>
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <Checkbox
                          name={'terms'}
                          data={data}
                          errors={errors}
                          label={'Accept Terms & Condition'}
                          handleOnChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <button type="submit" className="btn btn--base w-100">
                            {!showLoader ? (
                              'Sign In'
                            ) : (
                              <div
                                className="spinner-border text-dark"
                                role="status"
                              >
                                <span className="visually-hidden">...</span>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="have-account text-center">
                          <p className="have-account__text">
                            Don't Have An Account?{' '}
                            <Link to={'/signup'}>SignUp Now</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageMain>
  );
}
