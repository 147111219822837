import React from 'react';
import { Link } from 'react-router-dom';

export default function PageIntroHeader({ PageName }) {
  return (
    <section
      className="breadcumb py-120 bg-img"
      style={{
        backgroundImage: 'url(/assets/images/thumbs/feature/feature-img.png)',
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="breadcumb__wrapper">
              <h1 className="breadcumb__title"> {PageName}</h1>
              <ul className="breadcumb__list">
                <li className="breadcumb__item">
                  <Link className="breadcumb__link" to={'/'}>
                    <i className="las la-home"></i> Home
                  </Link>
                </li>
                <li className="breadcumb__item"> / </li>
                <li className="breadcumb__item">
                  <span className="breadcumb__item-text">{PageName}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
