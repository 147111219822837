import React from 'react';

export default function NavSearchBoxOverlay() {
  return (
    <div className="overlay-search-box position-relative">
      <div
        className="modal fade"
        id="search-box"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="search-overlay-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="las la-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="search-box">
                      <div className="input--group">
                        <input
                          type="text"
                          className="form--control style-two"
                          placeholder="Search...."
                        />
                        <button className="search-btn" type="submit">
                          <i className="las la-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
