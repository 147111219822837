import React from 'react';
import PageMain from '../../Components/Page/Main';
import PageIntroHeader from '../PageIntroHeader';

export default function Faqs() {
  return (
    <PageMain>
      <PageIntroHeader PageName={' FAQS '} />

      <div
        className="container"
        style={{ textAlign: 'left', marginTop: '50px' }}
      >
        <h2>Generally Asked Questions</h2>

        <div class="question">
          <h4>Do we sell pets?</h4>
          <p>No, we do not sell pets, we only sell products that pets need.</p>
        </div>

        <div class="question">
          <h4>What size would fit my pet?</h4>
          <p>
            Please refer to the size chart provided on the product details page
            for product specific sizing. For further queries, please contact
            Customer Service.
          </p>
        </div>

        <div class="question">
          <h4>What are the modes of payment we accept?</h4>
          <p>
            Credit Cards, Cash on Delivery, Debit Cards, UPI and NetBanking.
          </p>
        </div>

        <div class="question">
          <h4>Do we customize?</h4>
          <p>
            Yes, we do customize according to your pet’s needs. We can have
            beds, collars, leashes, name tags, bowls, toys, and almost anything
            else specially designed to suit your pet.
          </p>
        </div>
      </div>
    </PageMain>
  );
}
