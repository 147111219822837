import React from 'react';

export default function HeaderTopTwo() {
  return (
    <div className="header-top-two">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <h6 className="header-two__text">Welcome to Petocareo!</h6>
          <div className="d-flex flex-wrap justify-content-between">
            <ul className="social-list style-two">
              <li className="social-list__item">
                <a
                  href="https://www.facebook.com/"
                  className="social-list__link"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>{' '}
              </li>
              <li className="social-list__item">
                <a
                  href="https://www.twitter.com/"
                  className="social-list__link"
                >
                  {' '}
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li className="social-list__item">
                <a
                  href="https://www.linkedin.com/"
                  className="social-list__link"
                >
                  {' '}
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="social-list__item">
                <a
                  href="https://www.pinterest.com/"
                  className="social-list__link"
                >
                  {' '}
                  <i className="fab fa-pinterest-p"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
