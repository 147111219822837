export const getRandomIncreasedNumber = (originalNumber) => {
  originalNumber = Number(originalNumber);
  const increasedValue = originalNumber * (37 / 100);
  const result = Math.round(originalNumber + increasedValue);
  if (isNaN(result)) {
    return null;
  }
  return result;
};

export const capitalizeFirstLetter = (inputString) => {
  if (inputString && typeof inputString === 'string') {
    const lowercaseString = inputString.toLowerCase();
    const words = lowercaseString.split(' ');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(' ');
  } else {
    return '';
  }
};

// Function to remove duplicates from an array
export const removeDuplicatesItemFromArray = (array) => {
  const seen = new Set();
  return array.filter((item) => {
    const duplicate = seen.has(item._id);
    seen.add(item._id);
    return !duplicate;
  });
};
