import React from 'react';
import { Link } from 'react-router-dom';

export default function Category() {
  return (
    <div className="category-section py-120">
      <div className="container-fluid">
        <div className="row justify-content-center gy-4">
          <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
            <div className="category-item">
              <Link to={'/productcategory/Accessories'} className="category-item__thumb">
                <img src="/assets/images/thumbs/category/cate01.png" alt="" />
              </Link>
              <h4 className="category-item__title">
                <Link
                  to={'/productcategory/Accessories'}
                  className="category-item__title-link"
                >
                  Accessories
                </Link>
              </h4>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
            <div className="category-item">
              <Link
                to={'/productcategory/Dog'}
                className="category-item__thumb style-one"
              >
                <img src="/assets/images/thumbs/category/cate02.png" alt="" />
              </Link>
              <h4 className="category-item__title">
                <Link
                  to={'/productcategory/Dog'}
                  className="category-item__title-link"
                >
                  Dog Shop
                </Link>
              </h4>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
            <div className="category-item">
              <Link
                to={'/productcategory/Cat'}
                className="category-item__thumb style-two"
              >
                <img src="/assets/images/thumbs/category/cate03.png" alt="" />
              </Link>
              <h4 className="category-item__title">
                <Link
                  to={'/productcategory/Cat'}
                  className="category-item__title-link"
                >
                  Cat Shop
                </Link>
              </h4>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
            <div className="category-item">
              <Link
                to={'/productcategory/Fish'}
                className="category-item__thumb style-three"
              >
                <img src="/assets/images/thumbs/category/cate04.png" alt="" />
              </Link>
              <h4 className="category-item__title">
                <Link
                  to={'/productcategory/Fish'}
                  className="category-item__title-link"
                >
                  Fish Shop
                </Link>
              </h4>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
            <div className="category-item">
              <Link
                to={'/productcategory/Small'}
                className="category-item__thumb style-four"
              >
                <img src="/assets/images/thumbs/category/cate05.png" alt="" />
              </Link>
              <h4 className="category-item__title">
                <Link
                  to={'/productcategory/Small'}
                  className="category-item__title-link"
                >
                  Small Animal
                </Link>
              </h4>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
            <div className="category-item">
              <Link
                to={'/productcategory/Bird'}
                className="category-item__thumb style-five"
              >
                <img src="/assets/images/thumbs/category/cate06.png" alt="" />
              </Link>
              <h4 className="category-item__title">
                <Link
                  to={'/productcategory/Bird'}
                  className="category-item__title-link"
                >
                  Bird Shop
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
