import React from 'react';
import PageMain from '../../Components/Page/Main';
import PageIntroHeader from '../PageIntroHeader';

export default function TermsConditions() {
  return (
    <div>
      <PageMain>
        <PageIntroHeader PageName={'Terms and Conditions'} />
        <div
          className="container"
          style={{ textAlign: 'left', marginTop: '50px' }}
        >
          <h3>Terms and Conditions</h3>
          <p>
            Welcome to <strong>Petocareo</strong>. These terms and conditions
            outline the rules and regulations for the use of our UPI (Unified
            Payments Interface) and Payment Gateway services.
          </p>{' '}
          <br />
          <h4>1. Introduction</h4>
          <p>
            By using our UPI and Payment Gateway services, you agree to comply
            with and be bound by these terms and conditions. If you do not agree
            with any part of these terms, you may not use our services.
          </p>{' '}
          <br />
          <h4>2. Acceptance of Terms</h4>
          <p>
            By using our UPI and Payment Gateway services, you agree to comply
            with and be bound by these terms and conditions. If you do not agree
            with any part of these terms, you may not use our services.
          </p>{' '}
          <br />
          <h4>3. Eligibility</h4>
          <p>
            To use our UPI and Payment Gateway services, you must be of legal
            age and have the legal capacity to enter into contracts. By using
            our services, you represent that you meet these eligibility
            requirements.
          </p>{' '}
          <br />
          <h4>10. Data Security</h4>
          <p>
            Petocareo is committed to maintaining the security of your data. We
            implement industry-standard security measures to protect your
            information from unauthorized access, disclosure, alteration, and
            destruction.
          </p>{' '}
          <br />
          <p>
            Your payment information is encrypted and securely transmitted using
            SSL (Secure Socket Layer) technology. However, it's important to
            note that no method of transmission over the internet or electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your information, we cannot guarantee
            its absolute security.
          </p>{' '}
          <br />
          <h4>11. Termination</h4>
          <p>
            Petocareo reserves the right to terminate or suspend access to our
            UPI and Payment Gateway services immediately, without prior notice
            or liability, for any reason whatsoever, including without
            limitation if you breach the terms.
          </p>{' '}
          <br />
          <h4>12. Governing Law</h4>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of <strong>[India/Haryana]</strong>. Any
            disputes arising from or in connection with these terms shall be
            subject to the exclusive jurisdiction of the courts in{' '}
            <strong>[Rohtak/Haryana]</strong>.
          </p>{' '}
          <br />
          <h4>13. Changes to Terms</h4>
          <p>
            Petocareo reserves the right to modify or replace these terms and
            conditions at any time. Changes will be effective immediately upon
            posting on our website. Your continued use of our services after any
            changes constitute acceptance of those changes.
          </p>{' '}
          <br />
          <h4>14. Contact Information</h4>
          <p>
            For any inquiries or concerns regarding our UPI and Payment Gateway
            services, please contact us at{' '}
            <a href="mailto:support@petocareo.com">support@petocareo.com</a>.
          </p>{' '}
          <br />
          <p>
            By using our UPI and Payment Gateway services, you acknowledge that
            you have read, understood, and agree to these terms and conditions.
          </p>{' '}
          <br />
          <p>
            Last updated: <strong>[18 Feb 2024]</strong>
          </p>{' '}
          <br />
        </div>
      </PageMain>
    </div>
  );
}
