import React from 'react';

export default function TextField({
  name,
  data,
  errors,
  label,
  type,
  handleOnChange,
  ...rest
}) {
  const defaultLable = label
    ? String(label)[0].toUpperCase() + String(name).slice(1)
    : String(name)[0].toUpperCase() + String(name).slice(1);
  return (
    <div className="form-group text-start">
      <label htmlFor={name} className="form--label">
        {defaultLable}
      </label>
      <input
        type={type ? type : 'text'}
        className="form--control"
        placeholder={defaultLable}
        id={name}
        name={name}
        value={data[name]}
        onChange={handleOnChange}
        {...rest}
      />
      {errors[name] !== undefined && (
        <div className="text-danger">{errors[name]}</div>
      )}
    </div>
  );
}
