import React, { useEffect, useState } from 'react';

import PageIntroHeader from '../PageIntroHeader';
import PageMain from '../../Components/Page/Main';
import Joi from 'joi-browser';
import TextField from '../../Components/Form/TextField';
import { useLocation } from 'react-router-dom';
import { VerifyGeneratedOTP } from '../../Services/UserAuth';

export default function VerifyOTP() {
  const location = useLocation();
  const emailAsState = location.state && location.state.email;
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState('');
  const [data, setData] = useState({
    email: '',
    otp: '',
    isForgotPassword: false,
  });

  const schema = {
    email: Joi.string().required().label('Email'),
    otp: Joi.string().required().label('Verify Otp').min(6).max(6),
    isForgotPassword: Joi.boolean().required(),
  };

  const validate = () => {
    const validationErrors = {};
    const result = Joi.validate(data, schema, {
      abortEarly: false,
    });
    console.log(result);
    if (!result.error) return {};

    // eslint-disable-next-line no-return-assign
    result.error.details.map(
      (item) => (validationErrors[item.path[0]] = item.message)
    );
    return validationErrors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaObj = { [name]: schema[name] };
    const result = Joi.validate(obj, schemaObj);
    if (!result.error) return {};
    return result.error.details[0].message;
  };

  const handleChange = ({ target }) => {
    const fieldData = { ...data };
    const fieldErrors = { ...errors };

    const errorMessage = validateProperty(target);
    if (Object.keys(errorMessage).length > 0)
      fieldErrors[target.name] = errorMessage;
    else delete fieldErrors[target.name];

    fieldData[target.name] = target.value;

    setData({ ...fieldData });
    setErrors({ ...fieldErrors });
  };

  const handleSubmit = async (e) => {
    console.log('handle submit called');
    e.preventDefault();
    // for loader
    setShowLoader(true);
    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      console.log('Errors ===>', errors);
      setErrors({ ...errors, ...formErrors });
      setShowLoader(false);
    } else if (Object.keys(formErrors).length === 0) {
      setShowLoader(true);
      // call signin user controller
      VerifyGeneratedOTP({
        email: data.email,
        otp: data.otp,
        isForgotPassword: data.isForgotPassword,
      })
        .then(({ data }) => {
          console.log('Otp Verified successfully', data);
          if (data.statusCode === 200) {
            // set token wala code => token aise milee ga => data.data.token
            // navigate wala code.
          } else {
            setFormSubmitError(data.message);
          }
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          setFormSubmitError(err);
          console.log('Error ==> ', err);
        });
    }
  };

  // updating the email via location's state
  useEffect(() => {
    console.log('emailAsState', emailAsState);
    setData({ ...data, email: emailAsState });
  }, [emailAsState]);

  return (
    <PageMain>
      <PageIntroHeader PageName={'Login'} />
      <section className="account py-120">
        <div className="account-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 d-none d-lg-block">
                <div className="account-thumb">
                  <img src="/assets/images/thumbs/login-img.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="account-form">
                  <div className="account-form__content mb-4">
                    <h3 className="account-form__title mb-2">
                      {' '}
                      Account Verification
                    </h3>
                    <p className="account-form__title mb-2">
                      {' '}
                      A verification otp has been sent to registered email,
                      Please paste that code in below box.
                    </p>
                  </div>
                  <form onSubmit={handleSubmit}>
                    {formSubmitError && (
                      <h5 className="text-danger text-center">
                        {formSubmitError}
                      </h5>
                    )}
                    <div className="row">
                      {(emailAsState === '' || emailAsState === undefined) && (
                        <div className="col-sm-12">
                          <TextField
                            name={'email'}
                            data={data}
                            errors={errors}
                            label={'Email'}
                            disabled={!emailAsState === ' ' ? true : false}
                            handleOnChange={handleChange}
                          />
                        </div>
                      )}

                      <div className="col-sm-12">
                        <TextField
                          name={'otp'}
                          type={'otp'}
                          data={data}
                          errors={errors}
                          label={'OTP'}
                          handleOnChange={handleChange}
                        />
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          {showLoader ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn--base w-100"
                            >
                              Verify Account
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageMain>
  );
}
