import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GetAllProducts } from '../../../Services/productService';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeFirstLetter, getRandomIncreasedNumber } from './utils';
import { Button, Carousel } from 'react-bootstrap';
import CustomDialog from '../../Modals/Modals';
import { AddProductToCartStore } from '../../../store/actions/userCartAction';

const TrendingProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [authToken, setAuthToken] = useState(null);

  // for handling dialog
  const [showDialog, setshowDialog] = useState(false);
  const dialogTitle = 'Authorization Alert';
  const dialogDescription = 'Please login to add product to your cart';

  const handleDialogClose = () => setshowDialog(false);
  const handleLogin = () => navigate('/signin');

  const createDialogFooter = () => {
    return (
      <>
        <Button
          variant="primary"
          className="btn btn--base-two pill btn--sm"
          onClick={handleLogin}
        >
          Login
        </Button>
        <Button
          variant="light"
          className="btn rounded-pill btn--sm text-dark"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
      </>
    );
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data: dataSets } = await GetAllProducts({ token: authToken });
        if (dataSets.statusCode === 200) {
          const trendingProducts = dataSets.data.productList
            .slice(0, 4)
            .map((item) => ({
              id: item._id,
              images: item.productImage,
              name: item.productName,
              category: item.petTypeId[0].petCategory,
              price: item.price,
            }));
          setProducts(trendingProducts);
        }
        setFetched(true);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    !fetched && fetchProducts();
  }, [auth, authToken]);

  // setting token
  useEffect(() => {
    if (auth.accessToken !== undefined) {
      console.log('token set ');
      setAuthToken(auth.accessToken);
    } else {
      console.log('not set');
    }
  }, [auth.accessToken]);

  const renderProductImage = (product) => {
    return product.images.length === 1 ? (
      <img src={product.images[0]} alt="" />
    ) : (
      <Carousel
        indicators={false}
        controls={false}
        style={{ width: '250px', height: 'auto' }}
      >
        {console.log('Product ==>', products)}
        {product.images.map((imgUrl, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={imgUrl} alt="" />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  const handleAddToCart = async ({ id, quantity }) => {
    if (
      authToken !== null &&
      authToken.length !== undefined &&
      authToken.length > 0
    ) {
      const response = dispatch(
        AddProductToCartStore({ id, quantity }, authToken)
      );
      console.log('handleAddToCart called response ==>', response);
    } else {
      setshowDialog(true);
    }
  };

  return (
    <div className="trending-product-section pb-120">
      <CustomDialog
        show={showDialog}
        title={dialogTitle}
        description={dialogDescription}
        footer={createDialogFooter()}
        handleClose={handleDialogClose}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading-two">
              <div className="section-heading-two__icon">
                <img src="/assets/images/icons/heading-img.png" alt="" />
              </div>
              <div className="section-heading-two__title-wrapper">
                <h3 className="section-heading-two__title">
                  Trending Products
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center gy-4">
          {products.map((product) => (
            <div
              key={product.id}
              className="col-lg-3 col-md-6 col-sm-6 col-xsm-6"
            >
              <div className="product-item style-two">
                <div className="product-item__thumb">
                  <Link
                    to={`/product_details/${product.id}`}
                    className="product-item__thumb-link"
                  >
                    {renderProductImage(product)}
                  </Link>
                  <button className="product-item__icon">
                    <span className="product-item__icon-style">
                      <i className="las la-heart"></i>
                    </span>
                  </button>
                </div>
                <div className="product-item__content">
                  <h5 className="product-item__title">
                    <Link
                      to={`/product_details/${product.id}`}
                      className="product-item__title-link"
                    >
                      {capitalizeFirstLetter(product.name)}
                    </Link>
                  </h5>
                  <h6 className="product-item__price">
                    <span className="product-item__price-new">
                      ₹{getRandomIncreasedNumber(product.price)}
                    </span>{' '}
                    ₹{product.price}
                  </h6>
                  <Button
                    onClick={() =>
                      handleAddToCart({ id: product.id, quantity: 1 })
                    }
                    className="btn btn--base-two pill btn--sm"
                  >
                    Add to Cart
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrendingProduct;
