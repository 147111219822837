import React from 'react';
import Slider from 'react-slick';
import { Container, Row, Col, Image } from 'react-bootstrap';

export default function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  const testimonialsData = [
    {
      id: 1,
      image: '/assets/images/thumbs/t-2.png',
      review:
        'Petocareo has been a blessing for my furry friends. The range of medicines and the expert veterinary consultations are exceptional. I trust Petocareo for the well-being of my pets!',
      name: 'Dianne Russell',
      designation: 'Customer',
    },
    {
      id: 2,
      image: '/assets/images/thumbs/testimonial-img.png',
      review:
        "Petocareo's prompt delivery and quality products have made caring for my pets stress-free. The convenience of ordering medicines and essentials online is unmatched. Highly recommended!",
      name: 'Johnson',
      designation: 'Customer',
    },
    {
      id: 3,
      image: '/assets/images/thumbs/t-3.png',
      review:
        "Petocareo's commitment to pet health is evident. Their knowledgeable team guided me through product choices, ensuring my pet's happiness and well-being. Thank you, Petocareo, for your excellent service!",
      name: 'James Rodrigo',
      designation: 'Customer',
    },
  ];

  return (
    <div
      className="testimonial-two-section bg-img"
      style={{
        backgroundImage: 'url(/assets/images/shapes/thumbs/test-two0.png)',
      }}
    >
      <Container fluid>
        <Row
          className="align-items-center flex-wrap justify-content-center"
          style={{ display: 'flex !important' }}
        >
          <Col lg={12}>
            <div className="testimonial-two-slick-slider">
              <Slider {...settings}>
                {testimonialsData.map((testimonial) => (
                  <div
                    key={testimonial.id}
                    className="row d-flex  align-items-center mb-40"
                    style={{ display: 'flex !important' }}
                  >
                    <Col lg={6}>
                      <div className="testimonial-thumb">
                        <Image src={testimonial.image} alt="" fluid />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="testimonial-two-slider">
                        <h4 className="testimonial-two-slider__review">
                          Customer Review
                          <span className="testimonial-two-slider__review-shape">
                            <Image
                              src="/assets/images/shapes/review-shape.png"
                              alt=""
                              fluid
                            />
                          </span>
                        </h4>
                        <p className="testimonial-two-slider__desc">
                          {testimonial.review}
                        </p>
                        <div className="testimonial-two-slider__details">
                          <h4 className="testimonial-two-slider__name">
                            {testimonial.name}
                          </h4>
                          <span className="testimonial-two-slider__designation">
                            {testimonial.designation}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
