import React from 'react';
import { Link } from 'react-router-dom';

export default function AdsBannerTwo() {
  return (
    <div className="add-two-section pt-60">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Link to={'/product_details'} className="add-two-thumb">
              <img src="/assets/images/thumbs/add-two01.png" alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
