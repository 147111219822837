import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [], // Array to store products in the cart
  },
  reducers: {
    // Add a product to the cart
    addToCart: (state, action) => {
      const { id, quantity } = action.payload;
      const existingProduct = state.items.find((item) => item.id === id);

      if (existingProduct) {
        existingProduct.quantity += quantity;
      } else {
        state.items.push({ id, quantity });
      }
    },

    // Remove a product from the cart
    removeFromCart: (state, action) => {
      const productId = action.payload;
      state.items = state.items.filter((item) => item.id !== productId);
    },
    getAllItemsFromStoreCart: (state, action) => {
      return state.items;
    },

    clearCart: (state) => {
      state.items = [];
      return state;
    },

    syncAllItem: (state, action)=>{
      state.items = action.payload;
      return state;
    }
  },
});

export const {
  addToCart,
  removeFromCart,
  getAllItemsFromStoreCart,
  clearCart,
  syncAllItem
} = cartSlice.actions;
export default cartSlice.reducer;
