import React from 'react';
import PageMain from '../../Components/Page/Main';
import PageIntroHeader from '../PageIntroHeader';

const ShippingPolicy = () => {
  return (
    <PageMain>
      <PageIntroHeader PageName={' RefundPolocy Policy for Petocareo'} />
      <div
        className="container"
        style={{ textAlign: 'left', marginTop: '50px' }}
      >
        <h3>Shipping Policy - Petocareo</h3>
        <p>Last Updated: 18 Febuary 2024</p>

        <p>
          <strong>Owner:</strong> Dinesh Dangi
        </p>
        <p>
          Thank you for choosing Petocareo for all your pet needs. Our shipping
          policy is designed to provide you with transparency and assurance
          regarding the delivery of authentic, veterinarian-approved products
          for your beloved animals.
        </p>
        <br />
        <h4>Processing Time:</h4>
        <p>
          We understand the importance of receiving your orders promptly. Once
          you place an order on our website, our team will process it within 7
          business days. During peak seasons or promotions, processing times may
          be subject to slight variations, but we strive to dispatch your order
          as swiftly as possible.
        </p>
        <br />
        <h4>Shipping Methods:</h4>
        <p>
          We collaborate with trusted shipping partners to ensure the safe and
          efficient delivery of your products. The shipping method selected at
          checkout will determine the estimated delivery time. Standard shipping
          typically takes 3 to 4 business days, while expedited options are
          available for those who wish to receive their orders sooner.
        </p>
        <br />
        <h4>Shipping Costs:</h4>
        <p>
          Shipping costs are calculated based on the weight of your order and
          the chosen shipping method. You can view the shipping costs during the
          checkout process before completing your purchase. We occasionally
          offer promotions with free shipping, so keep an eye on our website for
          special deals.
        </p>
        <br />
        <h4>International Shipping:</h4>
        <p>
          At this time, Petocareo primarily ships within India. If you are
          located outside these regions, please contact our customer support
          team to inquire about the possibility of international shipping.
        </p>
        <br />
        <h4>Tracking Information:</h4>
        <p>
          Once your order has been dispatched, you will receive a shipping
          confirmation email containing a tracking number. This number allows
          you to monitor the progress of your package as it makes its way to
          your doorstep.
        </p>
        <br />
        <h4>Authenticity Guarantee:</h4>
        <p>
          Petocareo takes pride in providing authentic products that have been
          approved by veterinary doctors. We source our inventory from reputable
          suppliers to ensure the well-being of your pets. If you have any
          concerns about the authenticity of a product, please contact our
          customer support team for assistance.
        </p>
        <br />
        <h4>Contact Information:</h4>
        <p>
          If you have any questions or concerns regarding our shipping policy,
          please feel free to reach out to us:
        </p>
        <p>
          <strong>Customer Support:</strong> support@petocareo.com
          <br />
          <strong>Phone:</strong> 9017184321
          <br />
          <strong>Address:</strong> PLOT NO. 109 SHASTRI NAGAR HISAR ROAD ROHTAK
          Rohtak Rohtak Rohtak Haryana - 124001 India
        </p>

        <p>
          Thank you for choosing Petocareo. We appreciate your trust in our
          commitment to delivering quality products for your cherished
          companions.
        </p>
        <br />
        <p>Sincerely,</p>
        <p>
          Dinesh Dangi <br />
          Owner, Petocareo
        </p>
      </div>
    </PageMain>
  );
};

export default ShippingPolicy;
