import { apiUrl } from './Config';
import { http } from './httpServices';

export function GetAllProductsInCart({ token }) {
  return http.get(`${apiUrl}/user/cart`, {
    headers: {
      'x-access-token': token,
      loginType: 2,
    },
  });
}

export function DeleteSingleProductsFromCart({ token, cartId, productId }) {
  return http.delete(`${apiUrl}/user/cart/${cartId}`, {
    headers: {
      'x-access-token': token,
      loginType: 2,
    },
    data: {
      productId: productId,
      quantity: 1,
    },
  });
}

// GetAllOrderedProducts
// user/orders

export function GetAllOrderedProducts({ token }) {
  return http.get(`${apiUrl}/user/orders`, {
    headers: {
      'x-access-token': token,
      loginType: 2,
    },
  });
}
