import React from 'react';
import PageMain from '../Components/Page/Main';
export default function PageNotFound404() {
  return (
    <PageMain>
      <div
        style={{
          color: '#444',
          margin: '0',
          font: 'normal 14px/20px Arial, Helvetica, sans-serif',
          height: '100%',
          backgroundColor: '#fff',
        }}
      >
        <div style={{ height: 'auto', minHeight: '100%' }}>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              marginTop: '100px',
            }}
          >
            <h1
              style={{
                margin: '0',
                fontSize: '150px',
                lineHeight: '150px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              404
            </h1>
            <h2 style={{ marginTop: '20px', fontSize: '30px' }}>Not Found</h2>
            <p>The resource requested could not be found on this server!</p>
          </div>
        </div>
      </div>
    </PageMain>
  );
}
