import React from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

const FilterListToggle = ({ options, value, selectToggle }) => {
  return (
    <ToggleButtonGroup
      type="radio"
      name="options"
      value={value}
      onChange={selectToggle}
      className="w-100"
    >
      {options.map(({ label, id, value }) => (
        <ToggleButton
          key={id}
          value={value}
          variant="light"
          className="rounded-3"
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default FilterListToggle;
