import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { FaUserCircle } from 'react-icons/fa';
import { GetLoginedUserDetails } from '../../../Services/UserAuth';
import { LogoutUserWithThunk } from '../../../store/actions/userActions';
import { GetAllCartItemsInStore } from '../../../store/actions/userCartAction';
import { GetAllProductType, GetPetCategory } from '../../../Services/productService';

const tabsMenu = [
  // {
  //   lable: 'Dogs',
  //   isDropdown: true,
  //   navlinkUrl: '',
  //   dropDownData: [
  //     // { icon: '', label: 'Food', linkTo: '/productcategory/dog/food' },
  //     { icon: '', label: 'Food', linkTo: '/product_details' },
  //     {
  //       icon: '',
  //       label: 'Treat & Chews',
  //       linkTo: '/productcategory/dog/treat&Chews',
  //     },
  //     {
  //       icon: '',
  //       label: 'Health & Wellness',
  //       linkTo: '/productcategory/dog/health&Wellness',
  //     },
  //     {
  //       icon: '',
  //       label: 'Accessories',
  //       linkTo: '/productcategory/dog/accessories',
  //     },
  //   ],
  // },
  // {
  //   lable: 'Cats',
  //   isDropdown: true,
  //   navlinkUrl: '',
  //   dropDownData: [
  //     { icon: '', label: 'Food', linkTo: '/productcategory/cats/food' },
  //     {
  //       icon: '',
  //       label: 'Treat & Chews',
  //       linkTo: '/productcategory/cats/treat&Chews',
  //     },
  //     {
  //       icon: '',
  //       label: 'Health & Wellness',
  //       linkTo: '/productcategory/cats/health&Wellness',
  //     },
  //     {
  //       icon: '',
  //       label: 'Accessories',
  //       linkTo: '/productcategory/cats/accessories',
  //     },
  //   ],
  // },
  // {
  //   lable: 'Small Animal',
  //   isDropdown: true,
  //   navlinkUrl: '',
  //   dropDownData: [
  //     { icon: '', label: 'Food', linkTo: '/productcategory/small/food' },
  //     {
  //       icon: '',
  //       label: 'Treat & Chews',
  //       linkTo: '/productcategory/small/treat&Chews',
  //     },
  //     {
  //       icon: '',
  //       label: 'Health & Wellness',
  //       linkTo: '/productcategory/small/health&Wellness',
  //     },
  //     {
  //       icon: '',
  //       label: 'Accessories',
  //       linkTo: '/productcategory/small/accessories',
  //     },
  //   ],
  // },
  // {
  //   lable: 'Large Animal',
  //   isDropdown: true,
  //   navlinkUrl: '',
  //   dropDownData: [
  //     { icon: '', label: 'Food', linkTo: '/productcategory/large/food' },
  //     {
  //       icon: '',
  //       label: 'Treat & Chews',
  //       linkTo: '/productcategory/large/treat&Chews',
  //     },
  //     {
  //       icon: '',
  //       label: 'Health & Wellness',
  //       linkTo: '/productcategory/large/health&Wellness',
  //     },
  //     {
  //       icon: '',
  //       label: 'Accessories',
  //       linkTo: '/productcategory/large/accessories',
  //     },
  //   ],
  // },
  {
    lable: 'Others',
    isDropdown: true,
    navlinkUrl: '',
    dropDownData: [
      { icon: '', label: 'About Us', linkTo: '/about' },
      { icon: '', label: 'Contact Us', linkTo: '/contact' },
    ],
  },
];


const getNavBar = async ()=>{
  try {
    const {data: {data :{ petCategoryList }}} = await GetPetCategory();
    const {data: {data :{ data : productTypes }}} = await GetAllProductType();

    return [...petCategoryList.map(category=>({
      lable: category.petCategory,
      isDropdown: true,
      navlinkUrl: '',
      dropDownData: productTypes.map(eachType=>({
        icon: '',
        label: eachType.name,
        linkTo: `/productcategory/${category._id}/${eachType._id}`,
      }))
    })) , ...tabsMenu ]
    
    
  } catch (error) {
      throw error;
  }
}

const HeaderTwo = () => {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isUserLogined, setIsUserLogined] = useState(false);
  const [logedInAuthUser, setLogedInAuthUser] = useState([]);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [navTabsMenu, setNavTabsMenu] = useState([])

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991 && isMenuActive) {
        toggleMenu();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMenuActive]);

  

  useEffect(()=>{
    getNavBar().then((data)=>{ setNavTabsMenu(data) }).catch(error => console.log(error));
  },[])

 

  // setting token
  useEffect(() => {
    console.log('cart ====>', cart);
    if (auth.accessToken === undefined || auth.accessToken === null)  return; 
    setIsUserLogined(true);
    GetLoginedUserDetails({ token: auth.accessToken }).then(
      ({ data: response }) => {
        setLogedInAuthUser([response.data]);
      }
    );
    // dispatch(GetAllCartItemsInStore(auth.accessToken))
  }, [auth.accessToken, cart, dispatch]);

  const handleLogOutAuthUser = () => {
    dispatch(LogoutUserWithThunk())
      .unwrap()
      .then(() => {
        console.log('Logout successful');
        setIsUserLogined(false);
        setLogedInAuthUser([]);
        handleUserMenuClose();
        navigate('/');
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });
  };

  return (
    <header className="header-two">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="navbar-brand " to={'/'}>
            <span className="logo">
              <img src="/assets/images/logo/h2-logo.png" alt="" />
            </span>
          </Link>

          <button
            className="navbar-toggler header-button style-two"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span id="hiddenNav">
              <i className="las la-bars"></i>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav nav-menu m-auto style-two">
              {navTabsMenu.map((item, i) => (
                <li
                  key={i}
                  className={`nav-item ${item.isDropdown ? 'dropdown' : ' '}`}
                >
                  <Link
                    to={item.navlinkUrl}
                    className="nav-link"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {item.lable}
                    {item.isDropdown && (
                      <span className="nav-item__icon">
                        <i className="las la-angle-down"></i>
                      </span>
                    )}
                  </Link>
                  {item.isDropdown && (
                    <ul className="dropdown-menu style-two">
                      {item.isDropdown &&
                        item.dropDownData.map((listedItem, j) => (
                          <li key={j} className="dropdown-menu__list">
                            <Link
                              className="dropdown-item dropdown-menu__link"
                              to={listedItem.linkTo}
                            >
                              {listedItem.label}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  )}
                </li>
              ))}
              <li className={`nav-item`}>
                <Link to={'/consult'} className="nav-link">
                  {' '}
                  Consultant{' '}
                </Link>
              </li>
            </ul>
          </div>
          <div className="header-info style-two">
            <div className="toggle-search-box style-two">
              <button
                type="button"
                className="toggle-button style-two"
                data-bs-toggle="modal"
                data-bs-target="#search-box"
                data-bs-whatever="@mdo"
              >
                <i className="las la-search"></i>
              </button>
            </div>
            <div className="header-info__cart">
              <NavLink to={'/cart'} className="header-info__link">
                <i className="fas fa-shopping-cart"></i>
              </NavLink>
              <span className="header-info__cart-quantity">
                {cart.items.length}
              </span>
            </div>
            <div className="header-info__user">
              {!isUserLogined ? (
                <NavLink to={'/signin'} className="header-info__link">
                  <i className="far fa-user"></i>
                </NavLink>
              ) : (
                <div>
                  <IconButton onClick={handleClick}>
                    <Avatar
                      sizes="small"
                      style={{
                        backgroundColor: '#efefef',
                        color: '#333333',
                        border: '2px solid #333333',
                      }}
                    >
                      {logedInAuthUser.length > 0 &&
                      logedInAuthUser[0] != null &&
                      logedInAuthUser[0].name[0] != null ? (
                        String(logedInAuthUser[0].name[0]).toUpperCase()
                      ) : (
                        <FaUserCircle />
                      )}
                    </Avatar>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleUserMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem LinkComponent={Link} to="/userProfile">
                      Profile
                    </MenuItem>
         
                    <MenuItem onClick={()=>navigate('/userOrders')}>
                      Orders
                    </MenuItem>
                    <MenuItem onClick={handleLogOutAuthUser}>Logout</MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default HeaderTwo;
