import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  return (
    <footer className="footer-two-area">
      <div className="footer-two__top-shape">
        <img src="/assets/images/shapes/footer-top-shape.png" alt="" />
      </div>
      <div className="footer-two__shape-one">
        <img src="/assets/images/shapes/footer-shape-one.png" alt="" />
      </div>
      <div className="footer-two__shape-two">
        <img src="/assets/images/shapes/footer-shape-two.png" alt="" />
      </div>
      <div className="footer-two-area__inner">
        <div className="container">
          <div className="row justify-content-center gy-5">
            <div className="col-xl-3 col-sm-6">
              <div className="footer-item">
                <div className="footer-item__logo">
                  <Link to={'/'}>
                    {' '}
                    <img src="/assets/images/logo/h2-logo.png" alt="" />
                  </Link>
                </div>
                <p className="footer-item__desc">
                  {' '}
                  PLOT NO. 109 SHASTRI NAGAR HISAR ROAD ROHTAK Rohtak Rohtak
                  Rohtak Haryana - 124001 India
                </p>
                <div className="footer-item__number">
                  <span className="footer-item__text">CALL US</span>
                  <span className="footer-item__link d-block">9017184321</span>
                </div>
              </div>
            </div>
            <div className="col-xl-1 d-xl-block d-none"></div>
            <div className="col-xl-2 col-sm-6">
              <div className="footer-item">
                <h5 className="footer-item__title"> Pages </h5>
                <ul className="footer-menu style-two">
                  <li className="footer-menu__item">
                    <Link to={'/about'} className="footer-menu__link">
                      {' '}
                      About Us{' '}
                    </Link>
                  </li>
                  <li className="footer-menu__item">
                    <Link to="/contact" className="footer-menu__link">
                      {' '}
                      Contact
                    </Link>
                  </li>

                  <li className="footer-menu__item">
                    <Link to={'/privacy'} className="footer-menu__link">
                      {' '}
                      Privacy Policy
                    </Link>
                  </li>

                  <li className="footer-menu__item">
                    <Link to={'/refund'} className="footer-menu__link">
                      Refund Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-sm-6">
              <div className="footer-item">
                <h5 className="footer-item__title"> Useful Link </h5>
                <ul className="footer-menu style-two">
                  <li className="footer-menu__item">
                    <Link
                      to={'/terms&conditions'}
                      className="footer-menu__link"
                    >
                      Terms and Conditions{' '}
                    </Link>
                  </li>
                  <li className="footer-menu__item">
                    <Link to={'/ShippingPolicy'} className="footer-menu__link">
                      Shipping Policy{' '}
                    </Link>
                  </li>
                  <li className="footer-menu__item">
                    <Link to={'/signin'} className="footer-menu__link">
                      Login{' '}
                    </Link>
                  </li>
                  <li className="footer-menu__item">
                    <Link to={'/signup'} className="footer-menu__link">
                      Registration{' '}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-1 d-xl-block d-none"></div>
            <div className="col-xl-3 col-sm-6">
              <div className="footer-item">
                <p className="footer-item__desc style-two">
                  Subscribe to our newsletter and get 10% off your first
                  purchase..
                </p>
                <div className="subscriber-form mb-3">
                  <input
                    type="text"
                    className="form-control form--control"
                    placeholder="Email Address"
                    aria-label="Recipient's username"
                  />
                  <button className="btn btn--base-two subscribe-button">
                    <i className="fas fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {/* <!-- Footer Top End--> */}

      {/* // <!-- bottom Footer --> */}
      <div className="bottom-footer style-two py-lg-5 py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bottom-footer__text">
                {' '}
                &copy; Copyright {getCurrentYear()} . All rights by{' '}
                <Link to="http://www.petocareo.com">Petocareo</Link>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
