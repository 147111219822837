import React from 'react';
import PageMain from '../../Components/Page/Main';
import PageIntroHeader from '../PageIntroHeader';

export default function Privacy() {
  return (
    <PageMain>
      <PageIntroHeader PageName={' Privacy Policy for Petocareo'} />
      <div>
        <div
          className="container"
          style={{ textAlign: 'left', marginTop: '50px' }}
        >
          <h3>Privacy Policy</h3>
          <p>
            Dinesh Dangi Petocareo (Opc) Private Limited is a privately held
            company established on July 7, 2023. Registered as a one-person
            company, it operates in Haryana, India. With an authorized share
            capital of INR 1.00 lac and a total paid-up capital of INR 1.00 lac,
            the company is currently active. The latest details regarding the
            annual general meeting of Dinesh Dangi Petocareo (Opc) Private
            Limited are unavailable, and the company is yet to furnish its first
            full-year financial statements to the registrar. Dinesh Dangi
            Petocareo (Opc) Private Limited is governed by one director, Dinesh.
            The Corporate Identification Number (CIN) of Dinesh Dangi Petocareo
            (Opc) Private Limited is U47734HR2023OPC113116. The registered
            office is situated at PLOT NO. 109 SHASTRI NAGAR HISAR ROAD ROHTAK
            Rohtak Rohtak Rohtak, Haryana.
          </p>
          <p>
            (herein after referred to as the: “Company”/ “We” / “Us” and “Our”)
            is managing and operating the mobile application/website
            www.petocareo.com (collectively referred to as the “ Platform”)
            through which it inter alia facilitates the sale of Pet Products and
            accessories (“Products”) to the users of the Platform (“User” or
            “Users” or “you” or “your”).
          </p>
          <p>
            This policy amongst describes: (i) the type of information that the
            Company may collect from you when you access or use its websites,
            application; and (ii) the Company’s practices for collecting, using,
            maintaining, protecting and disclosing that information.
          </p>
          <p>
            We encourage you to read this policy carefully to understand the
            Companies Policies and Practices regarding your information.
          </p>
          <p>
            We reserve the right to make changes to this Privacy Policy at any
            time and for any reason. We will alert you about any changes by
            updating the “Last updated” date of this Privacy Policy. You are
            encouraged to periodically review this Privacy Policy to stay
            informed of updates. You will be deemed to have been made aware of,
            will be subject to, and will be deemed to have accepted the changes
            in any revised Privacy Policy by your continued use of the
            Application after the date such revised Privacy Policy is posted.
          </p>

          <h3>Interpretation and Definitions</h3>
          <p>
            <strong>Interpretation:</strong> The words of which the initial
            letter is capitalized have meanings defined under the following
            conditions. The following definitions shall have the same meaning
            regardless of whether they appear in singular or in plural.
          </p>

          <h3>Definitions:</h3>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <strong>"Account"</strong> means a unique account created
              voluntarily by you to access our Service or parts of our Services.
            </li>
            <li>
              <strong>"Application"</strong> means the software program provided
              by the Company downloaded by You on any electronic device, named
              HUFT
            </li>
            <li>
              <strong>“Website”</strong> means www.petocareo.com
            </li>
            <li>
              <strong>"Cookies”</strong> We use cookies, permissions, and other
              trackers in our website and mobile applications that are used to
              collect and process data about you so we can provide you a better
              online experience as well as improve our services in terms of
              personalized offers.
            </li>
            <li>
              Additionally, you may encounter “cookies” or other similar files
              on certain pages of the website or app that are placed by third
              parties. The Company does not control the use of cookies by third
              parties.
            </li>
            <li>
              <strong>"Device"</strong> means any device that can access the
              Service such as a computer, a cell phone, or a digital tablet.
            </li>
            <li>
              <strong>"Personal Data"</strong> is any information that relates
              to an identified or identifiable individual.
            </li>
            <li>
              <strong>"Usage Data"</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </li>
            <li>
              <strong>"You"</strong> means the individual accessing or using the
              Platform
            </li>
          </ul>

          <h3>COLLECTION OF DATA</h3>
          <p>We may collect information about you in a variety of ways.</p>

          <h3>TYPES OF DATA COLLECTED</h3>
          <p>
            <strong>Personal Data:</strong>
          </p>
          <p>
            Demographic and other personally identifiable information (such as
            your name, email address, phone number, address, and your pet
            details) that you voluntarily give to us while installing the
            Application, online chatting with our representatives, usage of
            services such as spa booking, products view, selection of products,
            purchase of products, sharing feedback.
          </p>

          <p>
            <strong>Data Usage:</strong>
          </p>
          <p>
            Usage Data is collected automatically when using the platform. Usage
            Data may include information such as Your Device's Internet Protocol
            address (e.g., IP address), browser type, browser version, the pages
            of our Service that You visit, the time and date of your visit,
            unique device identifiers and other diagnostic data. When You access
            the Service by or through a mobile device, we may collect certain
            information automatically, including, but not limited to, the type
            of mobile device You use, your mobile device unique ID, the IP
            address of Your mobile device, Your mobile operating system, the
            type of mobile Internet browser You use, unique device identifiers
            and other diagnostic data. We may also collect information that Your
            browser and application sends whenever You visit the platform.
          </p>

          <div id="data-retention">
            <h3>Retention of Your Data</h3>
            <p>
              We will retain your information and any data for the period
              necessary to fulfil the purposes outlined in this Privacy Policy
              unless a longer retention period is required or permitted under
              the applicable law.
            </p>
          </div>

          <div id="data-disclosure">
            <h3>Disclosure of Your Data</h3>
            <p>
              We may share information we have collected about you in certain
              situations. Your information may be disclosed as follows:
            </p>
            <ul>
              <li>
                <strong>By Law or to Protect Rights:</strong> We believe the
                release of data about you is necessary to respond to legal
                process, to investigate or remedy potential violations of our
                policies, or to protect the rights, property, and safety of
                others, we may share your information as permitted or required
                by any applicable laws, rules, or regulations.
              </li>
              <li>
                <strong>Third-Party Service Providers:</strong> We may share
                your information with third parties that perform services for us
                as per our agreed terms including payment processing and
                financial information.
              </li>
              <li>
                <strong>Other Third Parties:</strong> We may share your
                information with third parties for the purpose of conducting
                general business analysis. We may also share your information
                with such third parties for marketing purposes, as permitted by
                law.
              </li>
            </ul>
          </div>
          <div id="data-usage">
            <h3>Use of Your Data</h3>
            <p>
              Having accurate information about you permits us to provide you
              with a smooth, efficient, and customized experience. Specifically,
              we may use information collected about you for the following
              purposes:
            </p>
            <ul>
              <li>
                <strong>To create and manage Your Account:</strong> For creating
                and giving you access to your registered account on Platform.
              </li>
              <li>
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </li>
              <li>
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </li>
              <li>
                <strong>
                  To provide You with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </strong>
              </li>
              <li>
                <strong>To Process payments and refunds.</strong>
              </li>
              <li>
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </li>
              <li>
                <strong>For other purposes:</strong> We may use Your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Service, products, and
                your experience.
              </li>
            </ul>
          </div>

          <div id="childrens-privacy">
            <h3>Children’s Privacy</h3>
            <p>
              Our Platform is not directed towards minors. We do not knowingly
              collect Personal Information from any User who is under 18
              (Eighteen) years.
            </p>
          </div>
          <div id="consent">
            <h3>CONSENT</h3>
            <p>
              By using the Platform and/or by providing your information, you
              consent to the collection and use of the information you disclose
              on the Platform in accordance with this Privacy Policy, including
              but not limited to your consent for sharing your information as
              per this privacy policy.
            </p>
          </div>

          <div id="data-security">
            <h3>DATA SECURITY</h3>
            <p>
              We are committed to protecting your Data in our custody. We take
              reasonable steps to ensure appropriate physical, technical, and
              managerial safeguards are in place to protect your Data from
              unauthorized access, alteration, transmission, and deletion.
            </p>
          </div>

          <div id="options-data">
            <h3>OPTIONS REGARDING YOUR DATA</h3>
            <p>
              You may at any time review or change the information in your
              account or terminate your account by:
            </p>
            <ul>
              <li>
                Logging into your account settings and updating your account
              </li>
              <li>
                Contacting us using the contact information provided below
              </li>
            </ul>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, some information may be retained in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with legal requirements.
            </p>
          </div>

          <div id="emails-communications">
            <h3>Emails and Communications</h3>
            <p>
              If you no longer wish to receive correspondence, emails, or other
              communications from us, you may opt-out by:
            </p>
            <ul>
              <li>
                Noting your preferences at the time you register your account
                with the Application
              </li>
              <li>
                Logging into your account settings and updating your preferences
              </li>
              <li>
                Contacting us using the contact information provided below
              </li>
            </ul>
          </div>

          <div id="contact-us">
            <h3>Contact Us</h3>
            <p>
              For any further queries and complaints related to privacy under
              applicable laws and regulations, you could reach us at:
              support@petocareo.com
            </p>
          </div>
        </div>
      </div>
    </PageMain>
  );
}
