import React from 'react';
import { Link } from 'react-router-dom';

const NewArrival = () => {
  const newArrivals = [
    {
      id: 1,
      image: '/assets/images/thumbs/arrival-two01.png',
      name: 'Bonnie Dog Food (5kg)',
      category: 'dog',
      discount: 30,
      price: 150,
      isNew: false,
    },
    {
      id: 2,
      image: '/assets/images/thumbs/arrival-two02.png',
      name: 'Cat Food Chicken (2kg)',
      category: 'cat',
      discount: 0,
      price: 260,
      isNew: false,
    },
    {
      id: 3,
      image: '/assets/images/thumbs/arrival-two03.png',
      name: 'Royal Canin Food (2kg)',
      category: 'dog',
      discount: 0,
      price: 150,
      isNew: true,
    },
    {
      id: 4,
      image: '/assets/images/thumbs/arrival-two04.png',
      name: 'Bonnie Cat Food (4g)',
      category: 'cat',
      discount: 0,
      price: 158,
      isNew: false,
    },
  ];

  return (
    <div className="arrival-two-section py-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading-two">
              <div className="section-heading-two__icon">
                <img src="/assets/images/icons/heading-img.png" alt="" />
              </div>
              <div className="section-heading-two__title-wrapper">
                <h3 className="section-heading-two__title"> New Arrivals </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center gy-4">
          {newArrivals.map((arrival) => (
            <div
              key={arrival.id}
              className="col-xl-3 col-lg-3 col-sm-6 col-xsm-6"
            >
              <div className="product-item style-two">
                <div className="product-item__thumb">
                  <Link
                    to={'/product_details'}
                    className="product-item__thumb-link"
                  >
                    <img src={arrival.image} alt="" />
                  </Link>
                  <button className="product-item__icon">
                    <span className="product-item__icon-style">
                      <i className="las la-heart"></i>
                    </span>
                  </button>
                  {arrival.discount > 0 && (
                    <div className="product-item__badge">
                      <span className="badge badge--base-two">
                        -{arrival.discount}%
                      </span>
                    </div>
                  )}
                  {arrival.isNew && (
                    <div className="product-item__badge">
                      <span className="badge badge--base-two">new</span>
                    </div>
                  )}
                </div>
                <div className="product-item__content">
                  <h5 className="product-item__title">
                    <Link
                      to={'/product_details'}
                      className="product-item__title-link"
                    >
                      {arrival.name}
                    </Link>
                  </h5>

                  <h6 className="product-item__price">
                    <span className="product-item__price-new">
                      ₹{arrival.price}
                    </span>{' '}
                    ₹{arrival.price - (arrival.price * arrival.discount) / 100}
                  </h6>
                  <Link to={'/cart'} className="btn btn--base-two pill btn--sm">
                    Add to Cart{' '}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewArrival;
