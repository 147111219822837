import React from 'react';
import PageMain from '../../Components/Page/Main';
import PageIntroHeader from '../PageIntroHeader';

export default function RefundPolocy() {
  return (
    <PageMain>
      <PageIntroHeader PageName={' RefundPolocy Policy for Petocareo'} />
      <div>
        <div class="page">
          <div class="header"></div>
          <div class="translations-content-container">
            <div class="container">
              <div
                class="tab-content translations-content-item en visible"
                id="en"
              >
                <h1>Refund Policy for Petocareo</h1>

                <p>
                  At Petocareo, our aim is to provide high-quality products and
                  services to our valued customers. We understand that there may
                  be instances where you may need to initiate a refund. Below is
                  our Refund Policy to guide you through the process:
                </p>

                <h2>Eligibility for Refund:</h2>

                <ul>
                  <li>
                    <strong>Unused and Unopened Products:</strong>
                    <ul>
                      <li>
                        For health care medicines and other products, they must
                        be unused and still sealed in their original packaging.
                      </li>
                      <li>
                        Goodies and daily use products must also be in unused
                        condition.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Initiation Time:</strong>
                    <ul>
                      <li>
                        Refund requests must be initiated within 7 to 15 days of
                        receiving the product.
                      </li>
                    </ul>
                  </li>
                </ul>

                <h2>Refund Process:</h2>

                <ol>
                  <li>
                    <strong>Online Transactions:</strong>
                    <ul>
                      <li>
                        If the purchase was made through an online transaction,
                        the refund will be processed within 7 to 10 working days
                        from the date the refund request is approved.
                      </li>
                      <li>
                        The processing time may vary depending on the mode of
                        transaction used at the time of purchase.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Cash on Delivery (COD) Transactions:</strong>
                    <ul>
                      <li>
                        For COD transactions, our delivery personnel will
                        conduct an on-the-spot verification of the product's
                        authenticity and unused condition.
                      </li>
                      <li>
                        Once verified, the refund will be initiated, and the
                        amount will be returned to the customer's bank account.
                        This process is completed at the customer's doorstep.
                      </li>
                    </ul>
                  </li>
                </ol>

                <h2>Refund Verification Process:</h2>

                <ol>
                  <li>
                    <strong>Unused Product Verification:</strong>
                    <ul>
                      <li>
                        Our team will inspect the returned product to ensure it
                        is unused, unopened, and in its original packaging.
                      </li>
                      <li>
                        For COD transactions, our delivery personnel will verify
                        the product's condition at the customer's doorstep.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Identity Verification:</strong>
                    <ul>
                      <li>
                        Customers may be required to provide proof of identity
                        during the refund verification process.
                      </li>
                    </ul>
                  </li>
                </ol>

                <h2>Contacting Us:</h2>

                <p>
                  If you believe your product is eligible for a refund, please
                  contact our customer support team at{' '}
                  <a href="mailto:support@petocareo.com">
                    support@petocareo.com
                  </a>{' '}
                  with the following details:
                </p>

                <ul>
                  <li>Order number</li>
                  <li>Reason for refund</li>
                  <li>Clear images of the product (if applicable)</li>
                </ul>

                <p>
                  Our customer support team will guide you through the next
                  steps and provide any necessary assistance.
                </p>

                <h2>Note:</h2>

                <p>
                  Petocareo reserves the right to refuse a refund if the product
                  does not meet the eligibility criteria outlined in this
                  policy. This policy is subject to change without prior notice,
                  and customers are encouraged to review it periodically for
                  updates.
                </p>

                <p>
                  Thank you for choosing Petocareo. We value your trust and look
                  forward to providing you with excellent products and services.
                </p>

                <p>Sincerely,</p>

                <p>
                  Your Company Name
                  <br />
                  Petocareo Customer Support Team
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageMain>
  );
}
