import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageMain from '../Components/Page/Main';
import PageIntroHeader from './PageIntroHeader';
import { useSelector } from 'react-redux';
import { GetAllOrderedProducts } from '../Services/cartServices';
import { Card } from 'react-bootstrap';
import '../css/userOrders.css'

export default function UserOrders() {
  const auth = useSelector((state) => state.auth);
  const [fetched, setFetched] = useState(false);
  const [cartListItems, setCartListItems] = useState([]);

  // fetch products from cart
  useEffect(() => {
    const fetchOrderedProducts = async () => {
      try {
        const { data: dataSets } = await GetAllOrderedProducts({
          token: auth.accessToken,
        });
        if (dataSets.statusCode === 200) {
          console.log('dataSets.data', dataSets.data)
          setCartListItems(dataSets.data);
        }
        setFetched(true);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    !fetched && fetchOrderedProducts();
  }, [auth]);

  return (
    <PageMain>
      <PageIntroHeader PageName={'Your Orders'} />
      <div className="cart-section py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 center">
              <div className="section-heading">
                <h3 className="section-heading__title style-two">
                  {' '}
                  Your Orders Items{' '}
                  <span className="section-heading__bars"></span>
                </h3>
              </div>
            </div>
          </div>
          {cartListItems.map(cartListItem => (
            <div className="row  mt-2 mb-4">
              <div className="col-lg-10 offset-md-1">
                <Card>
                  <Card.Body className='row column-gap-3' style={{ justifyContent: 'space-between' }}>

                    <div className="col-lg-7">
                      <Card.Title className='text-start' as="h5"><b>Order Id :</b> {cartListItem.razorpayOrderId}</Card.Title>
                      <Card.Text className='text-start'><b> Date : </b> {(new Date(cartListItem.createdAt)).toLocaleString()}</Card.Text>
                      <Card.Text className='text-start'><b>Total Items : </b> {cartListItem.totalItemsQuantity}</Card.Text>
                      <Card.Text className='text-start'><b>Amount : </b> ₹{cartListItem.totalAmount}</Card.Text>
                      {/* <Button variant="primary">Go somewhere</Button> */}
                      <div className="row">
                        <div className="col-lg-12">
                          <table className="table table--responsive--lg cart-table">
                            <thead>
                              <tr>
                                <th>Product Name</th>
                                <th>Until Price</th>
                                <th>Qty</th>
                                <th>Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cartListItem.products.map((eachItem, i) => (
                                <tr key={i}>
                                  <td data-label="Product Name">
                                    <div className="customer">
                                      <div className="customer__thumb">
                                        <img
                                          src={eachItem?.productId?.productImage?.length ? eachItem?.productId?.productImage[0] : ''}
                                          alt={eachItem?.productId?.productName}
                                        />
                                      </div>
                                      <div className="customer__content">
                                        <h6 className="customer__name">
                                          {eachItem?.productId?.productName}
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td data-label="Unit Price"> ₹{eachItem?.productId?.price} </td>
                                  <td data-label="Qty">
                                    <div className="qty-cart d-flex style-two">
                                      <div className="product-qty">
                                        <h6 className="customer__name">{eachItem?.quantity}</h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td data-label="Subtotal">
                                    {' '}
                                    ₹{eachItem?.productId?.price * eachItem?.quantity}{' '}
                                  </td>
                                  {/* <td data-label="Delete">
                            <button
                              type="button"
                              className="delete-icon"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Delete"
                              // onClick={() => {
                              //   deleteProductFromCart(eachItem.productId);
                              // }}
                            >
                              {' '}
                              <i className="las la-trash-alt"></i>{' '}
                            </button>
                          </td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>

                      </div>
                    </div>
                    <div className="col-lg-3 ml-2 text-end">
                      <Card.Title className='text-start' as="h5">Shipping Details</Card.Title>
                      <Card.Text className='text-start'><b>Amount : </b> {cartListItem.address}</Card.Text>
                      <Card.Text className='text-start'><b>Pin code : </b> {cartListItem.pinCode}</Card.Text>
                      <Card.Text className='text-start'><b>Mobile : </b> {cartListItem.mobileNumber}</Card.Text>
                    </div>
                  </Card.Body>
                </Card>   
              </div>

            </div>
          ))}
          <div className="flex">
              <Link
                to="/productcategory"
                className="btn btn--base pill shopping-cart__pr mb-3"
              >
                CONTINUE SHOPPING
              </Link>
            </div>
        </div>
      </div>
    </PageMain>
  );
}


// (
//   <tr>
//     <td colSpan="5">
//       <h6 className="customer__name text-center">
//         The product card is currently empty, indicating that
//         there are no products to display.
//       </h6>
//     </td>
//   </tr>
// )