import React, { useEffect, useState } from 'react';
import PageMain from '../Components/Page/Main';
import PageIntroHeader from './PageIntroHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetSingleProductDetails } from '../Services/productService';
import { getRandomIncreasedNumber } from '../Components/Home/Main/utils';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AddProductToCartStore } from '../store/actions/userCartAction';
import CustomDialog from '../Components/Modals/Modals';

export default function ProductDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [authToken, setAuthToken] = useState(null);
  const [productData, setProductData] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [selectedProductImages, setSelectedProductImages] = useState([]);
  const [placeholderImage, setPlaceholderImage] = useState([]);
  const [productInfoTable, setProductInfoTable] = useState({
    Veg: '',
    Vendor: '',
    Type: '',
    Category: '',
    Size: [],
  });
  const [productQuantity, setProductQuantity] = useState(1);
  const [isDataFetched, setIsDataFetched] = useState(false);

  // for handling dialog
  const [showDialog, setshowDialog] = useState(false);
  const dialogTitle = 'Authorization Alert';
  const dialogDescription = 'Please login to add product to your cart';

  const handleDialogClose = () => setshowDialog(false);
  const handleLogin = () => navigate('/signin');
  const createDialogFooter = () => {
    return (
      <>
        <Button
          variant="primary"
          className="btn btn--base-two pill btn--sm"
          onClick={handleLogin}
        >
          Login
        </Button>
        <Button
          variant="light"
          className="btn rounded-pill btn--sm text-dark"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
      </>
    );
  };

  // setting token
  useEffect(() => {
    if (auth.accessToken !== undefined) {
      console.log('token set ');
      setAuthToken(auth.accessToken);
    }
  }, [auth.accessToken]);

  useEffect(() => {
    console.log('getting produc details  ==>', params);
    async function fetchData() {
      GetSingleProductDetails({ productId: params.productId })
        .then(({ data }) => {
          console.log('Product data for edit => ', data);
          if (data.statusCode === 200) {
            setProductData(data.data);
            setProductImages(data.data.productImage);
            setPlaceholderImage([data.data.productImage[0]]);
            setIsDataFetched(true);
            setProductInfoTable({
              Veg: data.data.isVeg ? 'vegetarian' : 'Non-vegetarian',
              Vendor: data.data.brandId.brandName,
              // Type: data.data.,
              Category: data.data.petTypeId.petCategory,
              Size: data.data.measurements.categoryOptions.map(
                (item) => item.categoryOptionName
              ),
            });
          } else {
            // navigate('/products');
          }
        })
        .catch((err) => {
          console.log('Error =>', err);
        });
    }
    if (params.productId !== undefined) {
      fetchData();
    }
  }, [params, params.productId, navigate]);

  const handleImageGalleryClick = (event) => {
    const clickedElementSrc = event.target.src;
    setSelectedProductImages([clickedElementSrc]);
  };

  const handleIncreaseProductQuantity = () =>
    setProductQuantity((prev) => prev + 1);

  const handleDecreaseProductQuantity = () =>
    setProductQuantity((prev) => (prev > 1 ? prev - 1 : prev));

  const handleAddToCart = async ({ id, quantity }) => {
    if (
      authToken !== null &&
      authToken.length !== undefined &&
      authToken.length > 0
    ) {
      dispatch(AddProductToCartStore({ id, quantity }, authToken));
    } else {
      setshowDialog(true);
    }
  };

  return (
    <PageMain>
      <PageIntroHeader PageName={' Product Details '} />
      <CustomDialog
        show={showDialog}
        title={dialogTitle}
        description={dialogDescription}
        footer={createDialogFooter()}
        handleClose={handleDialogClose}
      />
      {/* <!-- ======================product details two section start here ====================--> */}
      <div className="product-details-section py-120">
        <div className="container">
          <div className="row align-items-center gy-4">
            {!isDataFetched ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <div className="col-lg-7 pe-lg-5">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-9 col-lg-9 order-lg-2">
                      <div className="product-details__thumb">
                        {/* when we have started selecting image  */}
                        {selectedProductImages.length > 0 ? (
                          selectedProductImages.map((item, i) => (
                            <Link
                              key={i}
                              to={'/product_details'}
                              className="product-details__image"
                            >
                              <img src={item} alt="" />
                            </Link>
                          ))
                        ) : (
                          <img src={placeholderImage[0]} alt="" />
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-3 col-lg-3 order-lg-1 d-none d-md-block">
                      <ul className="product-details-gallery">
                        {productImages.length > 0 ? (
                          productImages.map((item, i) => (
                            <li
                              key={i}
                              className="product-details-gallery__image"
                              onClick={(e) => handleImageGalleryClick(e)}
                            >
                              <img src={item} alt="" />
                            </li>
                          ))
                        ) : (
                          <img src={placeholderImage[0]} alt="" />
                        )}
                      </ul>
                    </div>
                    {/* for smaller size */}
                    <div className="col-12 order-lg-1 d-md-none">
                      <ul
                        className="product-details-gallery"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          width: '100%',
                          overflowX: 'auto',
                        }}
                      >
                        {productImages.length > 0 ? (
                          productImages.map((item, i) => (
                            <li
                              key={i}
                              className="product-details-gallery__image"
                              onClick={(e) => handleImageGalleryClick(e)}
                            >
                              <img src={item} alt="" />
                            </li>
                          ))
                        ) : (
                          <img src={placeholderImage[0]} alt="" />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="product-info">
                    <h3 className="product-info__title">
                      {productData.productName}
                    </h3>

                    <h6 className="product-info__price">
                      <span className="product-info__price-new">
                        {'₹' +
                          getRandomIncreasedNumber(productData.price) +
                          ' '}
                      </span>{' '}
                      {'₹' + productData.price}
                    </h6>

                    <div className="table-responsive">
                      <table className="table table-sm">
                        <tbody>
                          {console.log(
                            'Object.entries(productInfoTable)',
                            Object.entries(productInfoTable)
                          )}
                          {Object.entries(productInfoTable).length > 0
                            ? Object.entries(productInfoTable).map(
                                (item, i) => (
                                  <tr key={i}>
                                    <td>
                                      <b>{item[0]}</b>
                                    </td>
                                    <td colSpan={2}>
                                      {Array.isArray(item[1]) ? (
                                        <div className="badge-group">
                                          {item[1].map((bage, b) => (
                                            <span
                                              key={b}
                                              className="badge bg-secondary"
                                              // onClick="selectBadge(this)"
                                            >
                                              {bage}
                                            </span>
                                          ))}
                                        </div>
                                      ) : (
                                        item[1]
                                      )}
                                    </td>
                                  </tr>
                                )
                              )
                            : null}
                        </tbody>
                      </table>
                    </div>

                    <div className="qty-cart d-flex product-style">
                      <span className="product-style__title">QTY :</span>
                      <div className="product-qty">
                        <button
                          type="button"
                          className="product-qty__decrement"
                          onClick={handleDecreaseProductQuantity}
                        >
                          <i className="las la-angle-down"></i>
                        </button>
                        <input
                          type="number"
                          className="product-qty__value"
                          value={productQuantity}
                          onChange={({ target }) => target.value}
                        />
                        <button
                          type="button"
                          className="product-qty__increment"
                          onClick={handleIncreaseProductQuantity}
                        >
                          <i className="las la-angle-up"></i>{' '}
                        </button>
                      </div>
                    </div>
                    <div className="product-info__button text-center center">
                      <Button
                        to="/"
                        className="btn btn--base pill product-info__Button"
                        onClick={() =>
                          handleAddToCart({
                            id: params.productId,
                            quantity: productQuantity,
                          })
                        }
                      >
                        Add To Cart
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* //Product description */}
        {/* <!-- =====================tab section start here ========================--> */}{' '}
        <div className="tab-section pb-120">
          <div className="container">
            <ul
              className="nav nav-pills mb-3 custom--tab"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Product Description
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabIndex="0"
              >
                <p className="tab-desc">{productData.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageMain>
  );
}
