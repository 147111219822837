import React from 'react';

export default function Checkbox({
  name,
  data,
  errors,
  label,
  handleOnChange,
  ...rest
}) {
  // const defaultLable = label
  //   ? String(label)[0].toUpperCase() + String(name).slice(1)
  //   : String(name)[0].toUpperCase() + String(name).slice(1);
  return (
    <div className="form-check text-start">
      <label htmlFor={name} className="form--label form-check-label">
        {label}
      </label>
      <input
        type="checkbox"
        className="form-check-input"
        id={name}
        name={name}
        value={data[name]}
        onChange={handleOnChange}
        {...rest}
      />
      {errors[name] !== undefined && (
        <div class="text-danger">{errors[name]}</div>
      )}
    </div>
  );
}
