import React from 'react';
// import PageHeader from '../Header/PageHeader';
import PageFooter from '../Footer/PageFooter';
import HomeHeader from '../../Home/Header';

export default function PageMain({ children }) {
  return (
    <div>
      <HomeHeader />
      {children}
      <PageFooter />
    </div>
  );
}
