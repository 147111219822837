import React from 'react';
import { Link } from 'react-router-dom';

export default function AdsBannerOne() {
  return (
    <div className="add-section pb-60">
      <div className="container">
        <div className="row gy-4">
          <div className="col-sm-6">
            <Link to={'/product_details'} className="add-one">
              <img src="/assets/images/thumbs/add-img01.png" alt="" />
            </Link>
          </div>
          <div className="col-sm-6">
            <div className="row gy-4">
              <div className="col-lg-12">
                <Link to={'/product_details'} className="add-one">
                  <img src="/assets/images/thumbs/add-img02.png" alt="" />
                </Link>
              </div>
              <div className="col-lg-12">
                <Link to={'/product_details'} className="add-one">
                  <img src="/assets/images/thumbs/add-img03.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
