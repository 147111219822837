import React from 'react';

import Banner from './Banner';
import Category from './Category';
import TrendingProduct from './TrendingProduct';
import FeatureProduct from './FeatureProduct';
import NewArrival from './NewArrival';
import AdsBannerOne from './AdsBannerOne';
import Testimonials from './Testimonials';
import AdsBannerTwo from './AdsBannerTwo';

export default function HomeMain() {
  return (
    <div>
      <Banner />
      <Category />
      <TrendingProduct />
      <FeatureProduct />
      <NewArrival />
      <AdsBannerOne />
      <Testimonials />
      <AdsBannerTwo />
    </div>
  );
}
