import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Carousel } from 'react-bootstrap';

import PageMain from '../Components/Page/Main';
import PageIntroHeader from './PageIntroHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllProducts, GetAllProductType, GetProducts } from '../Services/productService';
import { AddProductToCartStore } from '../store/actions/userCartAction';
import {
  capitalizeFirstLetter,
  getRandomIncreasedNumber,
  removeDuplicatesItemFromArray,
} from '../Components/Home/Main/utils';
import CustomDialog from '../Components/Modals/Modals';
import { toast } from 'react-toastify';

export default function ProductCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [productsRef, setProductsRef] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [noItemsMatchedMsg, setNoItemsMatchedMsg] = useState(
    'No Item Found To Applyed Filter'
  );
  const [shopByCategory, setShopByCategory] = useState([
    { label: 'Cat Food', value: 'Cat' },
    { label: 'Dog Food', value: 'Dog' },
    { label: 'Small Animal', value: 'Small' },
    { label: 'Large Animal', value: 'Large' },
    { label: 'Accessories', value: 'Accessories' },
    { label: 'Toys', value: 'Toys' },
  ]);
  const [vegCategory, setVegCategory] = useState([
    { label: 'Veg', value: 'veg' },
    { label: 'Non Veg', value: 'nonveg' },
  ]);

  const [selectedCheckboxForFilter, setSelectedCheckboxForFilter] = useState(
    []
  );

  const {categoryId, productType} = useParams();
  const [selectedProductType, setSelectedProductType] = useState([productType])
  const [ProductCategory, setProductCategory] = useState([]);
  const [timeout, setTimeoutRef] = useState(null);

  const getProductType = async () => {
    try {
   
      const { data: { data: { data: productTypes } } } = await GetAllProductType();
      console.log('productTypes', productTypes)
      setProductCategory(productTypes);
  } catch (error) {
     toast.error('Error', error.message)
  }
  } 

  useEffect(()=>{
  //  let isAvalable = shopByCategory.find(item => item.value.toLowerCase() === String(categoryId).toString().toLowerCase());
  //  if(isAvalable){
  //    setSelectedCheckboxForFilter(()=> [isAvalable.value]);
  //  } 

  setSelectedProductType([productType])
  
  }, [categoryId, productType]);

  const getProductsList = useCallback(async (query='')=>{
    let list = []
    try {
      const {data:{data:{productList}}} = await GetProducts({petCategory:categoryId, productType: selectedProductType, search: query});
      list = productList;
      setProducts(list);
      
    } catch (error) {
      console.log('Error', error)
      toast.error(error.message)
    }
    return list;
  },[categoryId, selectedProductType])

  useEffect(()=>{

    getProductsList()

  },[ getProductsList])

  useEffect(()=>{getProductType()},[])

  // for handling dialog
  const [showDialog, setshowDialog] = useState(false);
  const dialogTitle = 'Authorization Alert';
  const dialogDescription = 'Please login to add product to your cart';

  const handleDialogClose = () => setshowDialog(false);
  const handleLogin = () => navigate('/signin');

  const handleFilterChange = (e)=>
  setSearchText((prev) => e.target.value);
  useEffect(() => {
    if(timeout) clearTimeout(timeout);
    const Timeout =setTimeout(()=>{
      getProductsList(searchText)
    },[1000]);
    setTimeoutRef(Timeout)
   
  },[getProductsList, searchText]);

  // fetch products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data: dataSets } = await GetAllProducts({ token: authToken });
        if (dataSets.statusCode === 200) {
          console.log('productList', dataSets.data.productList)
          setProducts(dataSets.data.productList);
          setProductsRef(dataSets.data.productList);
        }
        setFetched(true);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    !fetched && fetchProducts();
  }, [auth, authToken]);

  // setting token
  useEffect(() => {
    if (auth.accessToken !== undefined) {
      console.log('token set ');
      setAuthToken(auth.accessToken);
    } else {
      console.log('not set');
    }
  }, [auth.accessToken]);

  const renderProductImage = (product) => {
    return product.productImage.length === 1 ? (
      <img src={product.productImage[0]} alt="" />
    ) : (
      <Carousel
        indicators={false}
        controls={false}
        style={{ width: '250px', height: 'auto' }}
      >
        {console.log('Product ==>', products)}
        {product.productImage.map((imgUrl, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={imgUrl} alt="" />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  const handleAddToCart = async ({ id, quantity }) => {
    if (
      authToken !== null &&
      authToken.length !== undefined &&
      authToken.length > 0
    ) {
      const response = dispatch(
        AddProductToCartStore({ id, quantity }, authToken)
      );
      console.log('handleAddToCart called response ==>', response);
    } else {
      setshowDialog(true);
    }
  };

  const createDialogFooter = () => {
    return (
      <>
        <Button
          variant="primary"
          className="btn btn--base-two pill btn--sm"
          onClick={handleLogin}
        >
          Login
        </Button>
        <Button
          variant="light"
          className="btn rounded-pill btn--sm text-dark"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
      </>
    );
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedProductType((preTypes)=>{
      if (e.target.checked) {
        return preTypes.includes(category)
              ? preTypes
              : [...preTypes, category];
      } else{
        return preTypes.filter((filter) => filter !== category);
      }
    });
    // setSelectedCheckboxForFilter((prevFilters) => {
    //   if (e.target.checked) {
    //     return prevFilters.includes(category)
    //       ? prevFilters
    //       : [...prevFilters, category];
    //   } else {
    //     return prevFilters.filter((filter) => filter !== category);
    //   }
    // });
  };

  // filter by search box,
  useEffect(() => {
    const productPrevState = [...products];
    const filteredProducts = productPrevState.filter((product) => {
      const productNameMatch = product.productName
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const descriptionMatch = product.description
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const brandNameMatch = product.brandId[0].brandName
        .toLowerCase()
        .includes(searchText.toLowerCase());

      return productNameMatch || descriptionMatch || brandNameMatch;
    });

    // If no items match, return an empty array
    const result = filteredProducts.length > 0 ? filteredProducts : [];
    console.log('result from searchbox ==>', result);
    // checking on to the basis of text in searchbox
    if (searchText.length === 0 && selectedCheckboxForFilter.length === 0) {
      if (products.length > 0) {
        setProducts(products);
      } else {
        setProducts(productsRef);
      }
    } else {
      setProducts(result);
    }
  }, []);

  const filterProductsByCategory = () => {
    let result = [];
    if (selectedCheckboxForFilter.length === 0) {
      return productsRef;
    }

    result = products.filter((product) =>
      selectedCheckboxForFilter.some((category) =>
        product.petTypeId.some((petType) => petType.petCategory === category)
      )
    );
    if (products.length < productsRef.length) {
      let cloneProductRef = [...productsRef];
      const elm = cloneProductRef.filter((product) =>
        selectedCheckboxForFilter.some((category) =>
          product.petTypeId.some((petType) => petType.petCategory === category)
        )
      );
      result.push(...elm);
    }
    console.log('selectedCheckboxForFilter =>', selectedCheckboxForFilter);
    console.log('filterProductsByCategory =>', result);
    return result;
  };

  // filter by vegcategory, aading veg/nonveg
  // const filterProductsByVegCategory = () => {
  //   let result = [];
  //   if (selectedCheckboxForFilter.length === 0) {
  //     return productsRef;
  //   }
  // result = products.filter((product) => {
  //   const vegType = product.isVeg === true ? 'veg' : 'nonveg';
  //   return selectedCheckboxForFilter.includes(vegType);
  // });

  // if (products.length < productsRef.length) {
  //   let cloneProductRef = [...productsRef];
  //   const elm = cloneProductRef.filter((product) => {
  //     const vegType = product.isVeg === true ? 'veg' : 'nonveg';
  //     return selectedCheckboxForFilter.includes(vegType);
  //   });
  //   result.push(...elm);
  // }
  //   return result;
  // };

  const filterProductsByVegCategory = () => {
    if (
      selectedCheckboxForFilter.includes('veg') &&
      selectedCheckboxForFilter.includes('nonveg')
    ) {
      return productsRef;
    }
    const result = products.filter((product) => {
      if (selectedCheckboxForFilter.includes('nonveg')) {
        return !product.isVeg;
      } else if (selectedCheckboxForFilter.includes('veg')) {
        return product.isVeg;
      }
      return product;
    });
    return result;
  };

  useEffect(() => {
    const updatedFilteredProducts = filterProductsByCategory();
    const updatedFilteredProductsByVegCategory = filterProductsByVegCategory();
    console.log('selectedCheckboxForFilter => ', selectedCheckboxForFilter);
    // Check if selectedCheckboxForFilter is empty
    if (selectedCheckboxForFilter.length === 0) {
      // If either array is non-empty, set the products accordingly
      const combinedFilteredProducts = removeDuplicatesItemFromArray([
        ...updatedFilteredProducts,
        ...updatedFilteredProductsByVegCategory,
      ]);
      setProducts(
        combinedFilteredProducts.length > 0
          ? combinedFilteredProducts
          : productsRef
      );
    } else {
      // selectedCheckboxForFilter is not empty, follow your original logic
      const combinedFilteredProducts = removeDuplicatesItemFromArray([
        ...updatedFilteredProducts,
        ...updatedFilteredProductsByVegCategory,
      ]);
      setProducts(
        combinedFilteredProducts.length > 0 ? combinedFilteredProducts : []
      );
    }
  }, [selectedCheckboxForFilter]);



  return (
    <PageMain>
      <CustomDialog
        show={showDialog}
        title={dialogTitle}
        description={dialogDescription}
        footer={createDialogFooter()}
        handleClose={handleDialogClose}
      />
      {/* <!--==================== Sidebar Overlay End ====================--> */}
      <div className="sidebar-overlay"></div>
      {/* <!--==================== Sidebar Overlay End ====================--> */}
      <PageIntroHeader PageName={'Product Category '} />
      <div className="product-category-two py-120">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="left-sidebar">
                <span className="close-sidebar d-lg-none d-block">
                  <i className="las la-times"></i>
                </span>
                <h6 className="sidebar-item__title">Shop By</h6>
                <div className="sidebar-item">
                  {/* Checkbox components */}
                  <Form>
                    {ProductCategory.map((category, index) => (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        id={`category-${index}`}
                        checked={selectedProductType.includes(
                          category._id
                        )}
                        label={category.name}
                        value={category._id}
                        onChange={handleCategoryChange}
                      />
                    ))}
                  </Form>
                </div>
                <h6 className="sidebar-item__title">Veg</h6>
                <div className="sidebar-item">
                  {/* Checkbox components */}
                  <Form>
                    {vegCategory.map((category, index) => (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        id={`category-${index}`}
                        checked={selectedCheckboxForFilter.includes(
                          category.value
                        )}
                        label={category.label}
                        value={category.value}
                        onChange={handleCategoryChange}
                      />
                    ))}
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <div className="product-sidebar-filter d-lg-none d-block">
                {/* Filter button */}
                <Button className="product-sidebar-filter__button">
                  <i className="las la-filter"></i>
                  <span className="text"> Filter </span>
                </Button>
              </div>
              <div className="row mb-3 gy-6">
                <Form.Group className="mb-3 d-flex">
                  <Form.Control
                    type="text"
                    value={searchText}
                    placeholder="Search Your Product Here"
                    onChange={handleFilterChange}
                  />
                </Form.Group>
              </div>
              <div className="row justify-content-center gy-4">
                {products.length === 0 ? (
                  <h6 className="mt-3 text-muted text-center">
                    {noItemsMatchedMsg}
                  </h6>
                ) : (
                  products.map((product, index) => (
                    <div
                      key={index}
                      className="col-lg-4 col-md-6 col-sm-6 col-xsm-6"
                    >
                      <div className="product-item style-two">
                        <div className="product-item__thumb">
                          <Link
                            to={`/product_details/${product._id}`}
                            className="product-item__thumb-link"
                          >
                            {renderProductImage(product)}
                          </Link>
                          <button className="product-item__icon">
                            <span className="product-item__icon-style">
                              <i className="las la-heart"></i>
                            </span>
                          </button>
                        </div>
                        <div className="product-item__content">
                          <h5 className="product-item__title">
                            <Link
                              to={`/product_details/${product._id}`}
                              className="product-item__title-link"
                            >
                              {capitalizeFirstLetter(product.productName)}
                            </Link>
                          </h5>
                          <h6 className="product-item__price">
                            <span className="product-item__price-new">
                              ₹{getRandomIncreasedNumber(product.price)}
                            </span>{' '}
                            ₹{product.price}
                          </h6>
                          <Button
                            onClick={() =>
                              handleAddToCart({ id: product._id, quantity: 1 })
                            }
                            className="btn btn--base-two pill btn--sm"
                          >
                            Add to Cart
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </PageMain>
  );
}
