import React from 'react';
import PageMain from '../Components/Page/Main';
import PageIntroHeader from './PageIntroHeader';
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <PageMain>
      <PageIntroHeader PageName={'About Us'} />
      <div className="about-section py-120">
        <div className="container">
          <div className="row gy-5 flex-wrap-reverse align-items-center">
            <div className="col-lg-6">
              <div className="about-thumb">
                <img
                  src="/assets/images/thumbs/about/about02.png"
                  alt="about"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="section-heading style-two">
                  <h4 className="section-heading__subtitle"> About Us </h4>
                  <div className="col-md-6">
                    <h3 className="section-heading__title style-two ">
                      {' '}
                      We're Always Here For Our Customers.{' '}
                      <span className="section-heading__bars style-two"></span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="about-us">
                <p className="about-us__desc">
                  Petocareo, your online pet platform, pioneers pet consultancy
                  with over 5 years of dedicated experience. Offering essential
                  medicines and accessories for both large and small animals,
                  we're committed to providing expert advice and guidance to our
                  valued customers.
                </p>
                <div className="d-flex justify-content-betwwen flex-wrap mb-4">
                  <ul className="text-menu style">
                    <li className="text-menu__item">
                      <span className="text-menu__icon">
                        <i className="fas fa-check"></i>
                      </span>{' '}
                      Pet Experts:
                    </li>
                    <li className="text-menu__item">
                      <span className="text-menu__icon">
                        <i className="fas fa-check"></i>
                      </span>{' '}
                      Vet-Approved
                    </li>
                  </ul>
                  <ul className="text-menu">
                    <li className="text-menu__item">
                      <span className="text-menu__icon">
                        <i className="fas fa-check"></i>
                      </span>{' '}
                      All-in-One Pet Care:{' '}
                    </li>
                    <li className="text-menu__item">
                      <span className="text-menu__icon">
                        <i className="fas fa-check"></i>
                      </span>{' '}
                      Trusted and Well-being:{' '}
                    </li>
                  </ul>
                </div>
                <div className="about-us-author">
                  <div className="about-us-author__info d-flex align-items-center">
                    <div className="about-us-author__thumb">
                      <img
                        src="/assets/images/people/dineshDangi-new.jpg"
                        alt="Owner"
                        style={{
                          width: '100px',
                          height: 'auto',
                          borderRadius: '100%',
                        }}
                      />
                    </div>
                    <div className="about-us-author__title">
                      <h6 className="about-us-author__name">Dinesh Dangi</h6>
                      <span className="about-us-author__customer">
                        {' '}
                        Customer{' '}
                      </span>
                    </div>
                  </div>
                  <h5 className="about-us-author__text">
                    Over{' '}
                    <span className="about-us-author__text-style"> 1000+</span>{' '}
                    client all over the India.
                  </h5>
                </div>
                <div className="about-us-footer">
                  <span className="about-us-footer__text">
                    Want to learn more about us?{' '}
                    <Link to="/privacy" className="about-us-footer__link">
                      {' '}
                      Click here{' '}
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- =======================why choose section start here =================--> */}
      <div className="why-choose pt-120">
        <div className="why-choose__bg">
          <img src="/assets/images/thumbs/about/about04.png" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading ">
                <h4 className="section-heading__subtitle text-white">
                  {' '}
                  Choose Us{' '}
                </h4>
                <h3 className="section-heading__title style-two text-white style-three">
                  {' '}
                  Why Choose Us{' '}
                  <span className="section-heading__bars style-three"></span>
                </h3>
              </div>
            </div>
          </div>
          <div className="row gy-4 justify-content-center">
            <div className="col-lg-4 col-sm-6 col-xsm-6">
              <div className="choose-item">
                <div className="choose-item__thumb">
                  <img src="/assets/images/thumbs/about/about05.png" alt="" />
                </div>
                <div className="choose-item__content">
                  <h4 className="choose-item__title"> Modern Pet Toy </h4>
                  <p className="choose-item__desc">
                    Learn Content By Interacting Anything Expert Lesson A video.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-xsm-6">
              <div className="choose-item">
                <div className="choose-item__thumb">
                  <img src="/assets/images/thumbs/about/about06.png" alt="" />
                </div>
                <div className="choose-item__content">
                  <h4 className="choose-item__title">
                    {' '}
                    Healthy Pet Nutritions{' '}
                  </h4>
                  <p className="choose-item__desc">
                    Learn Content By Interacting Anything Expert Lesson A video.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-xsm-6">
              <div className="choose-item">
                <div className="choose-item__thumb">
                  <img src="/assets/images/thumbs/about/about07.png" alt="" />
                </div>
                <div className="choose-item__content">
                  <h4 className="choose-item__title"> Quality & Safety </h4>
                  <p className="choose-item__desc">
                    Learn Content By Interacting Anything Expert Lesson A video.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-xsm-6 d-lg-none d-block">
              <div className="choose-item">
                <div className="choose-item__thumb">
                  <img src="/assets/images/thumbs/about/about05.png" alt="" />
                </div>
                <div className="choose-item__content">
                  <h4 className="choose-item__title"> Modern Pet Toy </h4>
                  <p className="choose-item__desc">
                    Learn Content By Interacting Anything Expert Lesson A video.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ====================why choose section end here =====================--> */}
    </PageMain>
  );
}
