import { AddProductToCart, GetAllCartItems } from '../../Services/Cart';
import { addToCart, syncAllItem } from '../slices/userCartSlice';
import { toast } from 'react-toastify';

export const AddProductToCartStore = (
  product,
  accessToken,
  callback = () => {}
) => {
  return async (dispatch, getState) => {
    try {
      const { cart } = getState();
      const existingProduct = cart.items.find((item) => item.id === product.id);
      const result = {
        data: await AddProductToCart({
          token: accessToken,
          postData: {
            productId: product.id,
            quantity: product.quantity,
          },
        }),
      };

      dispatch(addToCart({ id: product.id, quantity: product.quantity }));

      if (!existingProduct) {
        callback(result, null);
      }
      toast.success('Product Added!')
      return result.data;
    } catch (error) {
      callback(null, error);
      toast.error(error.message)
      console.log('Error', error);
    }
  };
};

export const GetAllCartItemsInStore = (
  accessToken,
  callback = () => {}
) => {
  return async (dispatch) => {
    try {
      const result = await GetAllCartItems({
          token: accessToken
        });
      if(result.data?.statusCode !== 200){
        toast.error(result.data?.message) 
        return [];
      }
      const data = result?.data?.data?.length ? result.data?.data[0].products?.map(product=> ({id:product.productId, quantity: product.quantity})) : []
      dispatch(syncAllItem(data));
      return result.data;
    } catch (error) {
      callback(null, error);
      toast.error(error.message) 
    }
  };
};