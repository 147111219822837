import React, { useState } from 'react';
import PageIntroHeader from '../PageIntroHeader';
import PageMain from '../../Components/Page/Main';
import Joi from 'joi-browser';
import TextField from '../../Components/Form/TextField';
import { Link, useNavigate } from 'react-router-dom';
import { ResetUserPassword } from '../../Services/UserAuth';

export default function ResetPassword() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState('');

  const [data, setData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const schema = {
    email: Joi.string().required().label('Email'),
    password: Joi.string().required().label('Password'),
    confirmPassword: Joi.any()
      .valid(Joi.ref('password'))
      .required()
      .label('Confirm Password')
      .options({
        language: {
          any: {
            allowOnly: 'must match password',
          },
        },
      }),
  };

  const validate = () => {
    const validationErrors = {};
    const result = Joi.validate(data, schema, {
      abortEarly: false,
    });
    console.log(result);
    if (!result.error) return {};

    // eslint-disable-next-line no-return-assign
    result.error.details.map(
      (item) => (validationErrors[item.path[0]] = item.message)
    );
    return validationErrors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaObj = { [name]: schema[name] };
    const result = Joi.validate(obj, schemaObj);
    if (!result.error) return {};
    return result.error.details[0].message;
  };

  const handleChange = ({ target }) => {
    const fieldData = { ...data };
    const fieldErrors = { ...errors };

    const errorMessage = validateProperty(target);
    if (Object.keys(errorMessage).length > 0)
      fieldErrors[target.name] = errorMessage;
    else delete fieldErrors[target.name];

    if (target.type === 'checkbox') {
      fieldData[target.name] = !Boolean(data.terms);
    } else {
      fieldData[target.name] = target.value;
    }
    setData({ ...fieldData });
    setErrors({ ...fieldErrors });
  };

  const handleSubmit = async (e) => {
    console.log('handle submit called');
    e.preventDefault();
    // for loader
    setShowLoader(true);
    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      console.log('Errors ===>', errors);
      setErrors({ ...errors, ...formErrors });
      setShowLoader(false);
    } else if (Object.keys(formErrors).length === 0) {
      // call ResetUserPassword controller
      ResetUserPassword({ email: data.email, password: data.password })
        .then(({ data }) => {
          console.log('ResetUserPassword successfully', data);
          setShowLoader(false);
          if (data.statusCode === 200) {
            // set userContest data as global.
            return navigate('/');
          } else {
            setFormSubmitError(data.message);
          }
        })
        .catch((err) => {
          console.log('errror', err);
          setShowLoader(false);
          setFormSubmitError(err.message);
        });
    }
  };

  return (
    <PageMain>
      <PageIntroHeader PageName={'Login'} />
      <section className="account py-120">
        <div className="account-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 d-none d-lg-block">
                <div className="account-thumb">
                  <img src="/assets/images/thumbs/login-img.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="account-form">
                  <div className="account-form__content mb-4">
                    <h3 className="account-form__title mb-2">
                      {' '}
                      Reset Your Password{' '}
                    </h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    {formSubmitError && (
                      <h5 className="text-danger text-center">
                        {formSubmitError}
                      </h5>
                    )}
                    <div className="row">
                      <div className="col-sm-12">
                        <TextField
                          name={'email'}
                          data={data}
                          errors={errors}
                          label={'Email'}
                          handleOnChange={handleChange}
                        />
                      </div>
                      <div className="col-sm-6">
                        <TextField
                          name="password"
                          label="Password"
                          type="password"
                          data={data}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-sm-6">
                        <TextField
                          name="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          data={data}
                          errors={errors}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <button type="submit" className="btn btn--base w-100">
                            {!showLoader ? (
                              'Reset Password'
                            ) : (
                              <div
                                className="spinner-border text-dark"
                                role="status"
                              >
                                <span className="visually-hidden">...</span>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <p className="have-account__text">
                          Already Have An Account?{' '}
                          <Link to={'/signin'}>Sign In Now</Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageMain>
  );
}
