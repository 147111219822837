import React from 'react';
import { categoryList, ratingList } from '../constants';
import CheckboxProton from '../common/CheckboxProton';
import FilterListToggle from '../common/FilterListToggle';
import SliderProton from '../common/SliderProton';

import './style.css';

function FilterPanel({
  selectedCategory,
  selectToggle,
  selectedRating,
  selectRating,
  cuisines,
  changeChecked,
  selectedPrice,
  changePrice,
}) {
  return (
    <div>
      {/* Category  */}
      <div className="input-group">
        <p className="label">Category</p>
        <FilterListToggle
          options={categoryList}
          value={selectedRating}
          selectToggle={selectToggle}
        />
      </div>
      {/* Cusienes  */}
      <div className="input-group">
        <p className="label">Cuisines</p>
        {cuisines &&
          cuisines.map((item, i) => (
            <CheckboxProton
              key={i}
              cuisine={item}
              changeChecked={changeChecked}
            />
          ))}
      </div>
      {/* Price Range  */}
      <div className="input-group">
        <p className="label">Price Range</p>
        <SliderProton value={selectedPrice} changePrice={changePrice} />
      </div>
      {/* start rating  */}
      <div className="input-group">
        <p className="label">Rating</p>
        <FilterListToggle
          options={ratingList}
          value={selectedCategory}
          selectToggle={selectRating}
        />
      </div>
    </div>
  );
}

export default FilterPanel;
