import React from 'react';
import { Link } from 'react-router-dom';

export default function Banner() {
  return (
    <section
      className="banner-two-section"
      style={{ backgroundImage: 'url(assets/images/shapes/banner-bg.png)' }}
    >
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6">
            <div className="banner-two-content">
              <h3 className="banner-two-content__subtitle">Save 20 -30% Off</h3>
              <h1 className="banner-two-content__title">
                Everything your pet need
              </h1>
              <Link to={'/productcategory'} className="btn btn--base-two pill">
                SHOP NOW
              </Link>
            </div>
          </div>
          <div className="col-md-6 d-md-block d-none">
            <div className="banner-two-section__thumb">
              <img src="/assets/images/thumbs/banner-two-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
