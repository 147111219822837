import React, { useState } from 'react';
import Joi from 'joi-browser';

import PageIntroHeader from '../PageIntroHeader';
import PageMain from '../../Components/Page/Main';
import TextField from '../../Components/Form/TextField';
import Checkbox from '../../Components/Form/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import { SignUpUser } from '../../Services/UserAuth';

export default function Signup() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    mobileno: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false,
  });
  // signupform => verify Otp => signup user token => ekContext me save karne hai yeha se setkrne hai
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  const [formSubmitError, setFormSubmitError] = useState('');

  const schema = {
    name: Joi.string().required().label('Name'),
    mobileno: Joi.string().required().label('Mobile No'),
    email: Joi.string().email().required().label('Email Address'),
    password: Joi.string().min(6).required().label('Password'),
    confirmPassword: Joi.string().required().label('Confirm Password'),
    acceptTerms: Joi.required().label('Required'),
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const updatedFormData = { ...formData };

    if (type === 'checkbox') {
      updatedFormData[name] = !Boolean(formData.acceptTerms);
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);

    const updatedErrors = { ...errors };
    const validationObject = { [name]: updatedFormData[name] };
    const { error } = Joi.validate(validationObject, { [name]: schema[name] });

    if (error) {
      updatedErrors[name] = error.details[0].message;
    } else {
      delete updatedErrors[name];
    }
    setErrors(updatedErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoader(true);
    const { error } = Joi.validate(formData, schema, { abortEarly: false });
    if (error) {
      const updatedErrors = {};
      error.details.forEach((detail) => {
        updatedErrors[detail.path[0]] = detail.message;
      });
      setErrors(updatedErrors);
      setFormSubmitError(
        'Form contains errors. Please fix them and try again.'
      );

      // checking password and confirmPassword
      if (formData.password !== formData.confirmPassword) {
        setErrors({ ...errors, password: 'Password did not matched' });
      }
      setShowLoader(false);
    } else {
      // call signin user controller
      SignUpUser({
        email: formData.email,
        password: formData.password,
        name: formData.name,
        mobileNumber: formData.mobileno,
      })
        .then(({ data }) => {
          console.log('data ====>', data.statusCode); // otp send hoo gay hai
          console.log('User signed up successfully');
          setShowLoader(false);
          if (data.statusCode === 200) {
            return navigate('/verifyOTP', { state: { email: formData.email } });
          } else {
            setFormSubmitError(data.message);
          }
        })
        .catch((err) => {
          setShowLoader(false);
          setFormSubmitError(err.message);
          console.log('Error ==> ', err);
        });
    }
  };

  return (
    <PageMain>
      <PageIntroHeader PageName={'Registration'} />
      <section className="account py-120">
        <div className="account-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 d-none d-lg-block">
                <div className="account-thumb">
                  <img src="/assets/images/thumbs/login-img.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="account-form">
                  <div className="account-form__content mb-4">
                    <h3 className="account-form__title mb-2">
                      Sign Up Your Account
                    </h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    {formSubmitError && (
                      <h5 className="text-danger text-center">
                        {formSubmitError}
                      </h5>
                    )}
                    <div className="row">
                      <div className="col-sm-12">
                        {/* form for submitting data  */}

                        <div className="row">
                          <div className="col-sm-6">
                            <TextField
                              name={'name'}
                              data={formData}
                              errors={errors}
                              label={'name'}
                              handleOnChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <TextField
                              name={'email'}
                              type={'email'}
                              data={formData}
                              errors={errors}
                              label={'email'}
                              handleOnChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <TextField
                              name={'password'}
                              type={'Password'}
                              data={formData}
                              errors={errors}
                              label={'Password'}
                              handleOnChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <TextField
                              name={'confirmPassword'}
                              type={'Password'}
                              data={formData}
                              errors={errors}
                              label={'Confirm Password'}
                              handleOnChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <TextField
                              name={'mobileno'}
                              type={'number'}
                              data={formData}
                              errors={errors}
                              label={'mobileno'}
                              handleOnChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-12">
                            <Checkbox
                              name={'acceptTerms'}
                              data={formData}
                              errors={errors}
                              label={'AcceptTerms & Condition'}
                              handleOnChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <button type="submit" className="btn btn--base w-100">
                            {!showLoader ? (
                              'Sign Up'
                            ) : (
                              <div
                                className="spinner-border text-dark"
                                role="status"
                              >
                                <span className="visually-hidden">...</span>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="have-account text-center">
                          <p className="have-account__text">
                            Already Have An Account?{' '}
                            <Link to={'/signin'}>Sign In Now</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageMain>
  );
}
