import { apiUrl } from '../Config';
import { http } from '../httpServices';

export function AddProductToCart({ token, postData }) {
  return http.post(`${apiUrl}/user/cart`, postData, {
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
      loginType: '2',
    },
  });
}

export function GetAllCartItems({ token }) {
  return http.get(`${apiUrl}/user/cart`, {
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
      loginType: '2',
    },
  });
}
