import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const products = [
  {
    id: 1,
    category: 'cat',
    type: 'fish',
    name: 'Bonnie Dog Food (2.5kg)',
    image: '/assets/images/thumbs/fp01.png',
    price: {
      new: 980,
      old: 850,
    },
  },
  {
    id: 2,
    category: 'small-pet',
    type: 'dog',
    name: 'Bonnie Cat Pouch (2kg)',
    image: '/assets/images/thumbs/fp02.png',
    price: {
      new: 220,
      old: 120,
    },
  },
  {
    id: 3,
    category: 'fish',
    type: 'cat',
    name: 'Cat Treats Squid (400g)',
    image: '/assets/images/thumbs/fp04.png',
    price: {
      new: 180,
      old: 150,
    },
  },
  {
    id: 4,
    category: 'small-pet',
    type: 'dog',
    name: 'Coco Kat Kitten Milk (3kg)',
    image: '/assets/images/thumbs/fp03.png',
    price: {
      new: 360,
      old: 310,
    },
  },
];

export default function FeatureProduct() {
  const [filter, setFilter] = useState('all');

  const handleFilter = (category) => {
    setFilter(category);
  };

  const filteredProducts =
    filter === 'all'
      ? products
      : products.filter((product) => product.category === filter);

  return (
    <div className="feature-product-section py-120 section-bg-two">
      <div className="row gy-4">
        <div className="col-lg-12">
          <div className="section-heading-two">
            <div className="section-heading-two__icon">
              <img src="/assets/images/icons/heading-img.png" alt="" />
            </div>
            <div className="section-heading-two__title-wrapper">
              <h3 className="section-heading-two__title style-two">
                Featured Products{' '}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="filter">
              <button
                type="button"
                onClick={() => handleFilter('all')}
                className={`style-two ${
                  filter === 'all' ? 'mixitup-control-active' : ''
                }`}
              >
                All
              </button>
              <button
                type="button"
                onClick={() => handleFilter('cat')}
                className={`style-two ${
                  filter === 'cat' ? 'mixitup-control-active' : ''
                }`}
              >
                Cat
              </button>
              <button
                type="button"
                onClick={() => handleFilter('dog')}
                className={`style-two ${
                  filter === 'dog' ? 'mixitup-control-active' : ''
                }`}
              >
                Dog
              </button>
              <button
                type="button"
                onClick={() => handleFilter('small-pet')}
                className={`style-two ${
                  filter === 'small-pet' ? 'mixitup-control-active' : ''
                }`}
              >
                Small Pet
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="product">
              {filteredProducts.length === 0 ? (
                <p>No products related to the selected category.</p>
              ) : (
                <div className="row justify-content-center gy-4">
                  {filteredProducts.map((product) => (
                    <div
                      key={product.id}
                      className={`col-xl-3 col-sm-6 col-xsm-6 mix ${product.category} ${product.type}`}
                    >
                      <div className="product-item style-two">
                        <div className="product-item__thumb">
                          <Link
                            to={`/product_details/${product.id}`}
                            className="product-item__thumb-link"
                          >
                            <img src={product.image} alt="" />
                          </Link>
                        </div>
                        <div className="product-item__content">
                          <h5 className="product-item__title">
                            <Link
                              to={`/product_details/${product.id}`}
                              className="product-item__title-link"
                            >
                              {product.name}
                            </Link>
                          </h5>

                          <h6 className="product-item__price">
                            <span className="product-item__price-new">
                              ₹{product.price.new}
                            </span>{' '}
                            ₹{product.price.old}
                          </h6>
                          <Link
                            to="/cart"
                            className="btn btn--base-two pill btn--sm"
                          >
                            Add to Cart
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
