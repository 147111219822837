import React from 'react';
import './style.css';
import { BsSearch } from 'react-icons/bs';

function SearchBar({ value, changeInput }) {
  return (
    <div className="searchBar-wrap">
      <BsSearch size={24} color="#000" />
      <input
        type="text"
        placeholder="Woodland Hills"
        value={value}
        onChange={changeInput}
      />
    </div>
  );
}

export default SearchBar;
