import { toast } from 'react-toastify';
import { SignInUser } from '../../Services/UserAuth';
import { setAccessToken, clearAccessToken } from '../slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const LoginUserWithThunk = (credentials, callback = () => {}) => {
  return async (dispatch) => {
    try {
      // Make API call for user login
      const response = await SignInUser(credentials);
      const accessToken = response.data?.data?.token;
      dispatch(setAccessToken(accessToken));
      callback(response, null);
    } catch (error) {
      callback(null, error);
      console.log('Error', error);
    }
  };
};

export const LogoutUserWithThunk = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch, getState, rejectWithValue }) => {
    console.log('LogoutUserWithThunk called ====>');
    try {
      const state = getState();
      const accessToken = state.auth.accessToken;

      if (accessToken) {
        dispatch(clearAccessToken());
        toast.success('User Logged Out Successfully');
      }

      return null;
    } catch (error) {
      console.log('Error', error);
      return rejectWithValue(error);
    }
  }
);

// export const LogoutUserWithThunk = (callback = () => {}) => {
//   console.log('LogoutUserWithThunk called up  ======>');
//   return;
//   return async (dispatch, getState) => {
//     try {
//       const state = getState();
//       console.log('state ======>', state, state.auth.accessToken);
//       const accessToken = state.auth.accessToken; // Adjust based on your state structure
//       // state.accessToken

//       if (accessToken) {
//         toast.success('User Logged Out Successfully');
//         // navigate('/');
//         // dispatch(clearAccessToken());
//         // await SignOutUser(accessToken);
//       }
//       // Clear user data from state
//       callback(null);
//     } catch (error) {
//       callback(error);
//       console.log('Error', error);
//     }
//   };
// };
